import { SchematicDataTableWithCreateModal } from '../../components/schematicComponents'

import schema from './schema'

const ProjectList = () => (
	<SchematicDataTableWithCreateModal
		title='Project'
		schema={schema}
		resource='projects'
		dataTableProps={{ search: true }}
		actions={{ del: true, edit: true, show: true }}
	/>
)

export default ProjectList
