import { Button } from 'antd'

import { DataTable, renderers } from '../../components/dataView'

const columns = [
	{ field: 'number', title: 'No', render: renderers.text },
	{ field: 'created', title: 'Date', render: renderers.time },
	{ field: 'investor_tg_username', title: 'User', render: renderers.username },
	{ field: 'investor_first_name', title: 'First name', render: renderers.text },
	{ field: 'investor_last_name', title: 'Last name', render: renderers.text },
	{ field: 'amount', title: 'Amount', render: renderers.number },
	{ field: 'currency', title: 'Currency', render: renderers.text },
	{ field: 'state', title: 'State', render: renderers.text },
	{ field: 'invoice_detail', title: 'Details', render: renderers.text },
	{
		field: 'invoice_url',
		title: 'Link',
		render: v => (
			<Button type='link'>
				<a href={v}>Pay</a>
			</Button>
		)
	}
]

const PaymentList = () => <DataTable columns={columns} listProps={{ canCreate: false }} actions={{ show: true }} />

export default PaymentList
