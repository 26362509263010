import { DataTable, renderers } from '../../components/dataView'

const columns = [
	{ field: 'number', title: 'No', render: renderers.text },
	{ field: 'investor_first_name', title: 'First name', render: renderers.text },
	{ field: 'investor_last_name', title: 'Last name', render: renderers.text },
	{ field: 'investor_tg_username', title: 'TG name', render: renderers.username },
	{ field: 'amount', title: 'Amount', render: renderers.number },
	{ field: 'date', title: 'Date', render: renderers.date },
	{ field: 'period', title: 'Until', render: renderers.date },
	{ field: 'active', title: 'Active', render: renderers.bool }
]

const AgreementList = () => <DataTable columns={columns} actions={{ show: true, edit: true, del: true }} />

export default AgreementList
