import { Col, Row, Typography } from 'antd'
import { DateField, TextField } from '@refinedev/antd'

import { DataShow } from '../../components/dataView'

const { Title } = Typography

const PaymentShow = () => {
	return (
		<DataShow>
			{record => {
				return (
					<>
						<Row>
							<Col span={4}>Invoice № {record.number}</Col>
						</Row>
						<Row>
							<Col span={4}>Date:</Col>
							<Col span={4}>
								<DateField value={`${record.created}`} />
							</Col>
						</Row>
						<Row>
							<Col span={4}>Billed for:</Col>
							<Col span={4}>
								<TextField code copyable value={record.investor_tg_username} />
							</Col>
							<Col span={8}>
								<TextField value={`${record.investor_first_name} ${record.investor_last_name}`} />
							</Col>
						</Row>

						<Row>
							<Col span={4}>Amount:</Col>
							<Col span={4}>
								<TextField value={`${record.amount} ${record.currency}`} />
							</Col>
						</Row>
						<Row>
							<Col span={4}>Payment type:</Col>
							<Col span={4}>
								<TextField value={`${record.pay_type}`} />
							</Col>
						</Row>
						<Row>
							<Col span={4}>State:</Col>
							<Col span={4}>
								<TextField value={`${record.state}`} />
							</Col>
						</Row>
						<Row>
							<Col span={4}>Bank payment #:</Col>
							<Col span={4}>
								<TextField value={`${record.reg_pay_num}`} />
							</Col>
						</Row>
						<Row>
							<Col span={4}>Link:</Col>
							<Col span={4}>
								<a href={record.invoice_url}>{record.invoice_url}</a>
							</Col>
						</Row>
						<Row>
							<Col span={4}>Receipt:</Col>
							<Col span={8}>
								<a href={record.receipt}>{record.receipt}</a>
							</Col>
						</Row>
						<Title level={4}>Details</Title>
						{record.invoice_detail}
					</>
				)
			}}
		</DataShow>
	)
}

export default PaymentShow
