import dayjs from 'dayjs'
import {
	Show,
	useEditableTable
	// EditButton, SaveButton
} from '@refinedev/antd'
import { useOne, useResource } from '@refinedev/core'
import { Row, Col, Space, Statistic, Typography, Tag, Divider, Table, Form } from 'antd'

import { formatCurrency, renderers } from './../../components/dataView'
import { getDefaultTitle } from '../../components/schematicComponents/schemaRenders'

import schema from './schema'

const { Title, Text, Link } = Typography

const EmptyText = () => <Text type='secondary'>N/A</Text>

const StatisticsRow = ({ data, fields, mapper, prefix, suffix }) =>
	data &&
	fields && (
		<Row>
			{fields.map(field => {
				const v = data[field]
				const value = v ? `${prefix || ''}${mapper?.(v) || v}${suffix || ''}` : 'N/A'
				return (
					<Col span={Math.floor(24 / fields.length)} key={field}>
						<Statistic title={schema[field]?.title || getDefaultTitle(field)} value={value} />
					</Col>
				)
			})}
		</Row>
	)

// equity - post valuation, safe - cap
const HeaderMain = ({ round_type, valuation_cap, valuation_cap_type, discount_pct, post_valuation }) => {
	const isEquity = round_type === 'equity'

	return (
		<Space size='large'>
			<Space>
				<Title level={4} style={{ margin: 0 }}>
					{isEquity ? 'Post Valuation: ' : 'CAP:'}
				</Title>
				<Text style={{ fontSize: 24 }}>{formatCurrency(isEquity ? post_valuation : valuation_cap)}</Text>
				{!isEquity && valuation_cap_type && <Tag>{getDefaultTitle(valuation_cap_type)}</Tag>}
			</Space>
			{!isEquity && <Text>Discount: {`${discount_pct}%` || 'N/A'}</Text>}
		</Space>
	)
}

const Header = ({ date, deal_id, deal_name, deal_number, type, round_type, ...investEvent }) => (
	<Space style={{ display: 'flex', justifyContent: 'space-between' }}>
		<HeaderMain {...{ ...investEvent, round_type }} />
		<Space size='middle'>
			{date && <Text>{dayjs(date).format('ll')}</Text>}
			{deal_id && <Link href={`/deals/show/${deal_id}`}>{[deal_name, deal_number].join(' ')}</Link>}
			<Space size='small'>
				{type && <Tag color={schema.type.renderSchema?.[type]?.color}>{getDefaultTitle(type)}</Tag>}
				{round_type && (
					<Tag color={schema.round_type.renderSchema?.[round_type]?.color}>
						{schema.round_type.renderSchema?.[round_type]?.title || getDefaultTitle(round_type)}
					</Tag>
				)}
			</Space>
		</Space>
	</Space>
)

const ReEvalsTable = ({ deal_id }) => {
	const {
		tableProps,
		formProps
		// isEditing,
		// saveButtonProps,
		// cancelButtonProps,
		// editButtonProps
	} = useEditableTable({
		resource: `invest_events/evals/${deal_id}`
	})

	return (
		<>
			<Title level={4}>Re-evals</Title>
			<Form {...formProps}>
				<Table {...tableProps}>
					<Table.Column title='Date' key='date' dataIndex='date' render={renderers.date} />
					<Table.Column
						title='Syndicate Valuation'
						key='syndicate_valuation'
						dataIndex='syndicate_valuation'
						render={renderers.currency}
					/>
					<Table.Column title='Net Asset Value' key='net_asset_value' dataIndex='net_asset_value' render={renderers.currency} />
					<Table.Column title='Gross IRR' key='gross_irr' dataIndex='gross_irr' render={renderers.percent} />
					<Table.Column title='Net IRR' key='net_irr' dataIndex='net_irr' render={renderers.percent} />
					<Table.Column title='TVPI' key='tvpi' dataIndex='tvpi' render={renderers.percent} />
					{/* <Table.Column
						dataIndex='actions'
						key='actions'
						width={100}
						render={(_, record) => {
							if (isEditing(record.id)) {
								return (
									<Space>
										<SaveButton {...saveButtonProps} size='small' />
										<Button {...cancelButtonProps} size='small'>
											Cancel
										</Button>
									</Space>
								)
							}
							return (
								<Space>
									<EditButton {...editButtonProps(record.id)} size='small' />
								</Space>
							)
						}}
					/> */}
				</Table>
			</Form>
		</>
	)
}

const Footer = ({ investor, project_name, project_id }) => (
	<Space size='middle'>
		<Text>Investor: {investor ? investor : <EmptyText />}</Text>
		<Text>Project: {project_name ? <Link href={`/projects/show/${project_id}`}>{project_name}</Link> : <EmptyText />}</Text>
	</Space>
)

const InvestEventsShow = () => {
	const {
		resource: { name },
		id
	} = useResource()

	const { data } = useOne({ resource: name, id })
	const investEvent = data?.data

	return (
		<Show headerButtons={[]} title='Invest Event'>
			{investEvent && (
				<Space direction='vertical' size='large' style={{ width: '100%' }}>
					<Space direction='vertical' size='small' style={{ width: '100%' }}>
						<Header {...investEvent} />
						<Divider />
						<StatisticsRow data={investEvent} fields={['synd_shares', 'total_shares']} mapper={formatCurrency} />
						<StatisticsRow
							data={investEvent}
							fields={['interest_rate', 'synd_share_pct']}
							mapper={v => v.toFixed(2)}
							suffix='%'
						/>
					</Space>
					{investEvent.deal_id && <ReEvalsTable deal_id={investEvent.deal_id} />}
					<Footer {...investEvent} />
				</Space>
			)}
		</Show>
	)
}

export default InvestEventsShow
