import { Route } from 'react-router-dom'

import dealsPages from './deals'
import meetupsPages from './meetups'
import projectsPages from './projects'
import paymentsPages from './payments'
import invoicesPages from './invoices'
import investorsPages from './investors'
import campaignsPages from './campaigns'
import agreementsPages from './agreements'
import industriesPages from './industries'
import investmentsPages from './investments'
import investEventsPages from './investEvents'
import projectReportsPages from './projectReports'

import {
	TeamOutlined,
	MailOutlined,
	InboxOutlined,
	CoffeeOutlined,
	DollarOutlined,
	PushpinOutlined,
	FundViewOutlined,
	FolderOpenOutlined,
	FundProjectionScreenOutlined
} from '@ant-design/icons'

const pagesRoutes = {
	list: '',
	create: 'create',
	edit: 'edit/:id',
	show: 'show/:id'
}

const pages = {
	campaigns: {
		...campaignsPages,
		icon: <MailOutlined />
	},
	investors: {
		...investorsPages,
		icon: <TeamOutlined />
	},
	projects: {
		...projectsPages,
		icon: <InboxOutlined />
	},
	deals: {
		...dealsPages,
		icon: <FolderOpenOutlined />
	},
	investments: {
		...investmentsPages,
		icon: <FundProjectionScreenOutlined />
	},
	meetups: {
		...meetupsPages,
		icon: <CoffeeOutlined />
	},
	finance: {
		nested: {
			agreements: {
				...agreementsPages
			},
			invoices: {
				...invoicesPages
			},
			payments: {
				...paymentsPages
			}
		},
		icon: <DollarOutlined />
	},
	invest_events: {
		...investEventsPages,
		icon: <FundViewOutlined />
	},
	industries: {
		...industriesPages,
		icon: <PushpinOutlined />
	},
	project_reports: {
		...projectReportsPages,
		icon: <InboxOutlined />
	}
}

export const getFlatPagesRoutes = (pgs = pages) =>
	Object.entries(pgs).map(([key, { nested, ...rest }]) =>
		nested ? (
			getFlatPagesRoutes(nested)
		) : (
			<Route key={key} path={`/${key}`}>
				{Object.entries(rest).map(
					([childKey, Component]) =>
						pagesRoutes[childKey] !== undefined && (
							<Route
								key={childKey}
								element={<Component />}
								{...(!pagesRoutes[childKey] ? { index: true } : { path: pagesRoutes[childKey] })}
							/>
						)
				)}
			</Route>
		)
	)

const getPageResources = (key, pgs) => ({
	name: key,
	...Object.keys(pgs)
		.filter(pageKey => Object.keys(pagesRoutes).includes(pageKey))
		.reduce((acc, pageKey) => ({ ...acc, [pageKey || 'list']: `/${key}/${pagesRoutes[pageKey]}` }), {})
})

export const getPagesResources = (pgs = pages, parent = null) =>
	Object.entries(pgs).reduce(
		(acc, [key, { nested, icon, ...rest }]) =>
			nested
				? [...acc, ...getPagesResources(nested, key), { name: key, meta: { icon } }]
				: [...acc, { ...getPageResources(key, rest), meta: { icon, parent } }],
		[]
	)
