import React from 'react'
import { Col, Form } from 'antd'

import { AddButton } from './buttons'

export const ListEdit = ({ name, defaultValue, children, addLabel }) => (
	<Form.List name={name}>
		{(fields, { add, remove }) => {
			return (
				<>
					{fields.map(
						({ name, key, ...itemProps }, index) => React.cloneElement(children, { key, name, itemProps, index, remove }) // todo: make without cloneElement
					)}
					<Col span={4}>
						<Form.Item>
							<AddButton onClick={() => add(defaultValue)}>{addLabel}</AddButton>
						</Form.Item>
					</Col>
				</>
			)
		}}
	</Form.List>
)
