import { Row, Form, Select, InputNumber } from 'antd'

import { DateInput } from '../../inputs'
import CountrySelector from '../../selectors/country'
import FilterInputColumn from './wrappers/filterInputColumn'
import { RemoteSelector } from '../../selectors/querySelectors'
import { InvestorSelector } from '../../selectors/entitySelectors'

import { profileFields, profileFieldsDates, profileFieldsNumbers, profileFieldsCountry, profileFieldsInvestor } from '../../../utils/constants'

const InvestorFilter = ({ pathName, itemProps }) => {
	const form = Form.useFormInstance()
	const field = Form.useWatch(['filters', ...pathName, 'field'], form)

	const handleFieldChange = () => {
		;['values', 'min', 'max', 'date_from', 'date_to'].forEach(v => {
			form.setFieldValue(['filter', 'investor', ...pathName, v], undefined)
		})
	}

	const getValueSelectorByField = field => {
		if (profileFieldsCountry.includes(field)) return <CountrySelector />
		if (profileFieldsInvestor.includes(field)) return <InvestorSelector mode='multiple' />
		return <RemoteSelector path={`/investors/profile/fields`} query={{ name: field }} mode='multiple' placeholder='Select value' />
	}

	return (
		<Row gutter={[16, 16]}>
			<FilterInputColumn span={6} title='Field'>
				<Form.Item {...itemProps} name={[...pathName, 'field']} rules={[{ required: true }]}>
					<Select options={profileFields} onChange={handleFieldChange} />
				</Form.Item>
			</FilterInputColumn>
			{field && ![...profileFieldsDates, ...profileFieldsNumbers].includes(field) && (
				<FilterInputColumn span={6} title='Value'>
					<Form.Item {...itemProps} name={[...pathName, 'values']} rules={[{ required: true }]}>
						{getValueSelectorByField(field)}
					</Form.Item>
				</FilterInputColumn>
			)}
			{field && profileFieldsDates.includes(field) && (
				<>
					<FilterInputColumn title='Date from'>
						<DateInput {...itemProps} placeholder={null} name={[...pathName, 'date_from']} style={{ width: '100%' }} />
					</FilterInputColumn>
					<FilterInputColumn title='Date to'>
						<DateInput {...itemProps} placeholder={null} name={[...pathName, 'date_to']} style={{ width: '100%' }} />
					</FilterInputColumn>
				</>
			)}
			{field && profileFieldsNumbers.includes(field) && (
				<>
					<FilterInputColumn title='Min value'>
						<Form.Item name={[...pathName, 'min']}>
							<InputNumber style={{ width: '100%' }} />
						</Form.Item>
					</FilterInputColumn>
					<FilterInputColumn title='Max value'>
						<Form.Item name={[...pathName, 'max']}>
							<InputNumber style={{ width: '100%' }} />
						</Form.Item>
					</FilterInputColumn>
				</>
			)}
		</Row>
	)
}

export default InvestorFilter
