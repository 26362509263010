import { useState, useEffect, useCallback } from 'react'
import { useApiUrl, useCustomMutation } from '@refinedev/core'

const useRecipientsCount = (campId = null, { ignoreUpdateWithoutCampId = false, initialFilters = null } = {}) => {
	const [recipientsCount, setRecipientsCount] = useState(0)

	const apiUrl = useApiUrl()
	const { mutate } = useCustomMutation()

	const updateRecipientsCount = useCallback(
		(filters = null) => {
			if (ignoreUpdateWithoutCampId && !campId) return
			if (!campId && !filters) return setRecipientsCount('-')

			mutate(
				{
					url: `${apiUrl}/campaigns/count_audience`,
					method: 'post',
					values: {
						id: campId,
						filters: filters?.filter(f => f?.filter_type) || null
					},
					queryOptions: {
						enabled: !!campId
					},
					successNotification: false,
					errorNotification: false
				},
				{
					onSuccess: data => setRecipientsCount(data?.data?.count),
					onError: () => setRecipientsCount('-')
				}
			)
		},
		[apiUrl, mutate, ignoreUpdateWithoutCampId, campId]
	)

	useEffect(() => {
		updateRecipientsCount(initialFilters)
		// param initialFilters is array of objects, so it isn't able to be at deps
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateRecipientsCount])

	return [recipientsCount, updateRecipientsCount]
}

export default useRecipientsCount
