import { Button } from 'antd'
import { RocketOutlined } from '@ant-design/icons'
import { useApiUrl, useCustomMutation } from '@refinedev/core'

const CampaignRunButton = ({ campaignId, campaignName, campaignActive }) => {
	const { mutate } = useCustomMutation()
	const apiUrl = useApiUrl()

	const handleRun = () =>
		mutate({
			url: `${apiUrl}/campaigns/${campaignId}/run`,
			method: 'post',
			successNotification: {
				message: `Campaign ${campaignName} started`,
				type: 'success'
			}
		})

	return (
		<Button type='primary' onClick={handleRun} disabled={!campaignActive}>
			<RocketOutlined />
			Send to all
		</Button>
	)
}

export default CampaignRunButton
