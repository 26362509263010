import dayjs from 'dayjs'
import { DatePicker, Form, Space } from 'antd'

const TimeTrigger = () => {
	return (
		<Space wrap>
			<Form.Item
				name={['trigger', 'time']}
				getValueProps={value => ({
					value: value ? dayjs(value) : null
				})}
			>
				<DatePicker placeholder='Select time' format='YYYY-MM-DD HH:mm' showTime />
			</Form.Item>
		</Space>
	)
}

export default TimeTrigger
