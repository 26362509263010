import { Checkbox, Col, Form, Input, Row, Select } from 'antd'

import { v4 as uuid } from 'uuid'

import { commitTypes, profileFields } from '../../../utils/constants'
import { DealSelector } from '../../selectors/entitySelectors'
import ListCard from '../wrappers/listCard'
import { ListEdit } from '../../listEdit'

const ContextedDealSelector = ({ name, itemProps }) => {
	const form = Form.useFormInstance()
	const currentDeal = Form.useWatch(['content', 'actions', name, 'args', 'current'], form)

	return (
		<Row justify='space-between'>
			<Col span={18}>
				<Form.Item label='Deal' labelCol={4} name={name.concat(['args', 'deal_id'])} disabled={currentDeal} {...itemProps}>
					<DealSelector disabled={currentDeal} />
				</Form.Item>
			</Col>
			<Col>
				<Form.Item label='Current' labelCol={4} name={name.concat(['args', 'current'])} valuePropName='checked' {...itemProps}>
					<Checkbox />
				</Form.Item>
			</Col>
		</Row>
	)
}

const CTALinkAction = ({ name, itemProps, lang }) => (
	<Row gutter={[16, 16]}>
		<Col span={24}>
			<Form.Item
				label='Url'
				name={name.concat(['args', 'url', lang])}
				{...itemProps}
				rules={[{ required: true }, { type: 'url', warningOnly: true }, { type: 'string', min: 6 }]}
			>
				<Input />
			</Form.Item>
		</Col>
	</Row>
)

const CTAAdminAction = ({ name, itemProps, lang }) => (
	<Row gutter={[16, 16]}>
		<Col span={24}>
			<Form.Item label='Topic' name={name.concat(['args', 'topic', lang])} {...itemProps} rules={[{ required: true }]}>
				<Input />
			</Form.Item>
		</Col>
	</Row>
)

const CTAEditorAction = ({ name, itemProps }) => (
	<Row gutter={[16, 16]}>
		<Col span={24}>
			<Form.Item label='Field' name={name.concat(['args', 'field'])} {...itemProps} rules={[{ required: true }]}>
				<Select options={profileFields} />
			</Form.Item>
		</Col>
	</Row>
)

const CTADealCardAction = ({ name, itemProps }) => (
	<Row gutter={[16, 16]}>
		<Col span={12}>
			<ContextedDealSelector name={name} itemProps={itemProps} />
		</Col>
	</Row>
)

const CTADealCommitAction = ({ name, itemProps }) => (
	<Row gutter={[16, 16]}>
		<Col span={12}>
			<ContextedDealSelector name={name} itemProps={itemProps} />
		</Col>
		<Col span={12}>
			<Form.Item label='Commit type' labelCol={4} name={name.concat(['args', 'commit_type'])} {...itemProps} rules={[{ required: true }]}>
				<Select options={commitTypes} />
			</Form.Item>
		</Col>
	</Row>
)

const CTAMeetupAction = () => (
	<Row gutter={[16, 16]}>
		<Col span={12}></Col>
	</Row>
)

const actionTypes = [
	{
		label: 'Quiz answer',
		value: 'quiz_answer'
	},
	{
		label: 'External Link',
		value: 'link'
	},
	{
		label: 'Admin message',
		value: 'admin_message'
	},
	{
		label: 'Profile editor',
		value: 'editor_switch'
	},
	{
		label: 'Bonuses',
		value: 'bonus_switch'
	},
	{
		label: 'Meetups',
		value: 'meetups_switch'
	},
	{
		label: 'Deal card',
		value: 'deal_card'
	},
	{
		label: 'Deal commit',
		value: 'deal_commit'
	},
	{
		label: 'Deal showcase',
		value: 'deal_showcase'
	}
]

const actionInputs = {
	link: CTALinkAction,
	admin_message: CTAAdminAction,
	editor_switch: CTAEditorAction,
	deal_card: CTADealCardAction,
	deal_commit: CTADealCommitAction,
	meetup_card: CTAMeetupAction
}

const CTAActionCardHeadersCols = ({ name, itemProps, lang }) => (
	<>
		<Form.Item name={[name, 'id']} initialValue={uuid()}>
			<Input type='hidden' />
		</Form.Item>
		<Col span={8}>
			<Form.Item label='Action' {...itemProps} name={[name, 'type']} labelCol={{ span: 0 }}>
				<Select options={actionTypes} />
			</Form.Item>
		</Col>
		<Col span={12}>
			<Form.Item label='Button text' {...itemProps} name={[name, 'text', lang]} rules={[{ required: true }]} labelCol={{ span: 0 }}>
				<Input />
			</Form.Item>
		</Col>
	</>
)

const CTAAction = ({ name, itemProps, remove, lang }) => {
	const form = Form.useFormInstance()
	const actionType = Form.useWatch(['content', 'actions', name, 'type'], form)

	const InputRenderer = actionInputs[actionType]

	return (
		<ListCard HeaderCols={CTAActionCardHeadersCols} name={name} remove={remove} itemProps={itemProps} lang={lang}>
			{InputRenderer && <InputRenderer name={[name]} itemProps={itemProps} lang={lang} />}
		</ListCard>
	)
}

const CtaActions = ({ lang }) => (
	<ListEdit name={['content', 'actions']} addLabel='Add action'>
		<CTAAction lang={lang} />
	</ListEdit>
)

export default CtaActions
