import { Form, Space, Typography } from 'antd'

import { DealSelector } from '../../selectors/entitySelectors'

const { Title } = Typography

const Context = () => {
	return (
		<Space direction='vertical' size='small' style={{ display: 'flex' }}>
			<Title level={3}>Default Context</Title>
			<Form.Item name={['context', 'deal_id']} label='Deal' labelCol={{ span: 0 }}>
				<DealSelector />
			</Form.Item>
		</Space>
	)
}

export default Context
