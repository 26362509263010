import { Row, Col, Form, InputNumber, Select, Card, Space } from 'antd'

import { SchematicForm } from '../../schematicComponents'
import { dealFormInvestEventSchema } from '../../../pages/investEvents/schema'

import { currenciesOptions } from '../../../utils/options'
import { SyndSelector } from '../../selectors/querySelectors'

const { Item } = Form
const { Inputs } = SchematicForm

const Offer = () => {
	const form = Form.useFormInstance()

	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<Space direction='vertical' style={{ width: '100%' }}>
				<Row gutter={[16, 16]}>
					<Col span={18}>
						<Item label='Allocation' name='allocation' labelCol={{ span: 4 }} labelAlign='left'>
							<InputNumber style={{ width: '100%' }} />
						</Item>
					</Col>
					<Col span={6}>
						<Item name='currency'>
							<Select options={currenciesOptions} placeholder='Currency' />
						</Item>
					</Col>
				</Row>
				<Item label='Syndicators' name='syndicator_ids' labelCol={{ span: 3 }} labelAlign='left'>
					<SyndSelector mode='multiple' />
				</Item>
			</Space>
			<Card title='Invest Event' style={{ width: 600 }}>
				<Inputs schema={dealFormInvestEventSchema} form={form} itemNamePrefix={['invest_event']} />
			</Card>
		</div>
	)
}

export default Offer
