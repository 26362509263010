import { SchematicDataTableWithCreateModal } from './../../components/schematicComponents'

import schema from './schema'

const InvestorList = () => (
	<SchematicDataTableWithCreateModal
		title='Investor'
		schema={schema}
		resource='investors'
		dataTableProps={{ search: true }}
		actions={{ show: true, edit: true }}
	/>
)

export default InvestorList
