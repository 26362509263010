import { Edit, useStepsForm } from '@refinedev/antd'

import DealForm from '../../components/deals/dealForm'

export const DealEdit = () => {
	const { form, formProps, saveButtonProps, onFinish, ...props } = useStepsForm({
		redirect: 'show',
		successNotification: false
	})

	formProps.onFinish = () => onFinish(form.getFieldsValue(true))
	formProps.initialValues = {
		invest_event: {
			type: 'deal'
		},
		...(formProps.initialValues || {})
	}

	return (
		<Edit saveButtonProps={saveButtonProps}>
			<DealForm formProps={formProps} {...props} />
		</Edit>
	)
}

export default DealEdit
