import { Row, Form, InputNumber } from 'antd'

import { DateInput } from '../../inputs'
import FilterInputColumn from './wrappers/filterInputColumn'

const TrialFilter = ({ pathName }) => (
	<Row gutter={[16, 16]}>
		<FilterInputColumn title='Days remain' span={6}>
			<Form.Item name={[...pathName, 'days_remain']}>
				<InputNumber style={{ width: '100%' }} />
			</Form.Item>
		</FilterInputColumn>
		<FilterInputColumn title='Start from'>
			<DateInput name={[...pathName, 'start_from']} placeholder={null} />
		</FilterInputColumn>
		<FilterInputColumn title='Start to'>
			<DateInput name={[...pathName, 'start_to']} placeholder={null} />
		</FilterInputColumn>
		<FilterInputColumn title='End from'>
			<DateInput name={[...pathName, 'end_from']} placeholder={null} />
		</FilterInputColumn>
		<FilterInputColumn title='End to'>
			<DateInput name={[...pathName, 'end_to']} placeholder={null} />
		</FilterInputColumn>
		<FilterInputColumn title='Months from'>
			<Form.Item name={[...pathName, 'duration_month_from']}>
				<InputNumber style={{ width: '100%' }} />
			</Form.Item>
		</FilterInputColumn>
		<FilterInputColumn title='Months to'>
			<Form.Item name={[...pathName, 'duration_month_to']}>
				<InputNumber style={{ width: '100%' }} />
			</Form.Item>
		</FilterInputColumn>
	</Row>
)

export default TrialFilter
