import { SchematicDataTableWithCreateModal } from '../../components/schematicComponents'

import schema from './schema'

const IndustriesList = () => (
	<SchematicDataTableWithCreateModal
		editable
		schema={schema}
		title='Industry'
		resource='industries'
		actions={{ del: true }}
		dataTableProps={{ search: true }}
	/>
)

export default IndustriesList
