import { Typography, Space, Card } from 'antd'
import { green, blue } from '@ant-design/colors'

import TagsRow from '../../containers/tagsRow'
import { DataShow } from '../../components/dataView'
import LangContent from '../../containers/langContent'

const { Title, Paragraph, Link } = Typography

const ProjectHeader = ({ record: { industry_names, target_market, old_name, website } }) => (
	<div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
		<Space size='middle'>
			<TagsRow tags={industry_names?.map(i => ({ title: i, color: green[5] }))} />
			<TagsRow tags={target_market && [{ title: target_market, color: blue[5] }]} />
			<Paragraph type='secondary' style={{ margin: 0 }}>
				{old_name}
			</Paragraph>
		</Space>
		<Link href={website} target='_blank'>
			{website}
		</Link>
	</div>
)

const ProjectLangShortDescription = ({ record }) => {
	const lang = LangContent.useLang()

	return (
		<Card type='inner' size='small' title='Short Description'>
			<span style={{ margin: 0 }} dangerouslySetInnerHTML={{ __html: record.short_desc?.[lang] }} />
		</Card>
	)
}

const ProjectLangDescription = ({ record }) => (
	<LangContent content={record.desc}>
		<ProjectLangShortDescription record={record} />
	</LangContent>
)

const ProjectShow = () => {
	return (
		<DataShow>
			{record =>
				record && (
					<Space direction='vertical' size='large' style={{ width: '100%' }}>
						<ProjectHeader record={record} />
						<Title level={3} style={{ margin: 0 }}>
							{record.name}
						</Title>
						<ProjectLangDescription record={record} />
					</Space>
				)
			}
		</DataShow>
	)
}

export default ProjectShow
