import { makeAllFieldsRequired } from '../../components/schematicComponents/utils'

const unnecessaryFields = []

const schema = makeAllFieldsRequired(
	{
		type: { renderType: 'tag', renderSchema: { soft: { color: 'green' }, hard: { color: 'red' } }, sorter: {} },
		amount: { renderType: 'currency', sorter: {} },
		deal_id: { title: 'Deal', renderType: 'deal' },
		investor_id: { title: 'Investor', renderType: 'investor', sorter: {} },
		vehicle_name: { title: 'Vehicle', showInputWhen: false },
		vehicle_id: { title: 'Vehicle', renderType: 'legalEntity', hideColumn: true },
		updated: { renderType: 'date', showInputWhen: false, sorter: {} }
	},
	unnecessaryFields
)

export const dealInvestmentsSchema = Object.fromEntries(Object.entries(schema).filter(([k]) => k !== 'deal_id'))

export default schema
