import { SchematicDataTable } from '../../components/schematicComponents'

import schema from './schema'

const CampaignList = () => (
	<SchematicDataTable
		search
		schema={schema}
		title='Campaign'
		resource='campaigns'
		actions={{ show: true, edit: true, del: true, copy: true }}
	/>
)

export default CampaignList
