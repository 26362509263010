import { blue } from '@ant-design/colors'
import { CameraOutlined } from '@ant-design/icons'

const createFields = ['project_id', 'number', 'name']

const schema = Object.entries({
	id: { title: <CameraOutlined />, renderType: 'itemImage', renderSchema: { path: '/deals', field: 'logo' } },
	number_id: { title: 'Number', renderType: 'number', sorter: {} },
	project_id: { title: 'Project', renderType: 'project', required: true },
	name: { required: true },
	number: { required: true },
	stage: { renderType: 'tag', renderSchema: blue[5], sorter: {} },
	state: {
		renderType: 'tag',
		renderSchema: {
			collect_commits: { color: 'green' },
			completed: { color: 'green' },
			ic_approval: { title: 'IC Approval', color: 'yellow' },
			alloc_confirmation: { color: 'blue' },
			rejected: { color: 'red' },
			failed: { color: 'red' }
		},
		sorter: {}
	},
	allocation: { renderType: 'currency' },
	created: { renderType: 'date', sorter: {} },
	updated: { renderType: 'date' }
}).reduce((acc, [key, value]) => ({ ...acc, [key]: { ...value, showInputWhen: createFields.includes(key) } }), {})

export default schema
