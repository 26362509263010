import { Space, Table, Descriptions } from 'antd'

import { floatingValues } from '../steps/conditions'

import { formatCurrency } from '../../dataView'

const { Item: DescItem } = Descriptions

const FloatingFeesTable = ({ fees, currency }) => {
	if (!fees || !fees.length) return null

	const tableColumns = [
		{ key: 'fee', title: 'Fee', dataIndex: 'fee' },
		...fees.map(({ threshold }) => ({
			key: threshold,
			title: threshold ? `< ${formatCurrency(threshold, currency)}` : 'More',
			dataIndex: threshold || 'more'
		}))
	]

	const tableDataSource = Object.entries(floatingValues).reduce(
		(acc, [key, value]) => [
			...acc,
			{
				key,
				fee: value,
				...fees.reduce(
					(feeAcc, fee) => ({ ...feeAcc, [fee.threshold || 'more']: fee[key] !== undefined ? `${fee[key] * 100}%` : '—' }),
					{}
				)
			}
		],
		[]
	)

	return <Table columns={tableColumns} dataSource={tableDataSource} pagination={false} />
}

const DealConditions = ({ record }) => {
	const { currency } = record

	return (
		<Space direction='vertical' size='large' style={{ width: '100%' }}>
			<FloatingFeesTable fees={record.fees} currency={currency} />
			<Descriptions bordered>
				<DescItem label='SPV Fixed Fee'>{formatCurrency(record.spv_fee, currency)}</DescItem>
				<DescItem label='Minimum Commit Amount'>{formatCurrency(record.min_commit_amount, currency)}</DescItem>
			</Descriptions>
		</Space>
	)
}

export default DealConditions
