import {useLogin} from "@refinedev/core";
import {useEffect, useRef} from "react";

import {ThemedTitle} from "@refinedev/antd";
import {Layout, Space} from "antd";


// Todo: Update your Google Client ID here
const GOOGLE_CLIENT_ID = "1013127585776-0f2gehba18mebcai62pktk6tmetfvmsc.apps.googleusercontent.com";

export const Login = () => {
    const {mutate: login} = useLogin();

    const GoogleButton = () => {
        const divRef = useRef(null);

        useEffect(() => {
            if (typeof window === "undefined" || !window.google || !divRef.current) {
                return;
            }

            try {
                window.google.accounts.id.initialize({
                    ux_mode: "popup",
                    client_id: GOOGLE_CLIENT_ID,
                    callback: async (res) => {
                        if (res.credential) {
                            login(res);
                        }
                    },
                });
                window.google.accounts.id.renderButton(divRef.current, {
                    theme: "filled_blue",
                    size: "medium",
                    type: "standard",
                });
            } catch (error) {
                console.log(error);
            }
        }, []);

        return <div ref={divRef}/>;
    };

    return (
        <Layout style={{height: "100vh", justifyContent: "center", alignItems: "center"}}>
            <Space direction="vertical" align="center">
                <ThemedTitle text='AngelsDeck'
                             icon={<img
                                 width={32}
                                 src="https://thumb.tildacdn.com/tild6266-3335-4934-a165-313162383364/-/cover/286x276/center/center/-/format/webp/_angelsdeck.png"/>}
                             collapsed={false} wrapperStyles={{fontSize: "22px", marginBottom: "36px"}}/>
                <GoogleButton/>
            </Space>
        </Layout>
    );
};
