import { useMemo } from 'react'
import { Form } from 'antd'

import { getFormInputs } from './schemaRenders'

const FormInputs = ({ schema, form, itemNamePrefix = [] }) => {
	const fieldsToWatch = useMemo(
		() =>
			Object.entries(schema)
				.map(([, { showInputWhen: { field } = {} }]) => field)
				.filter(Boolean),
		[schema]
	)

	const formWatcher = Form.useWatch([...itemNamePrefix], form)
	const optimizedFormWatcher =
		formWatcher && Object.entries(formWatcher).reduce((a, [k, v]) => (fieldsToWatch.includes(k) ? ((a[k] = v), a) : a), {})
	const optimizedFormWatcherString = JSON.stringify(optimizedFormWatcher)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const inputs = useMemo(() => getFormInputs(schema, { formWatcher: optimizedFormWatcher, itemNamePrefix }), [schema, optimizedFormWatcherString])

	return inputs
}

const SchematicForm = ({ schema, form, formProps, ...props }) => (
	<Form {...formProps} {...props}>
		<FormInputs schema={schema} form={form} />
	</Form>
)

SchematicForm.Inputs = FormInputs

export default SchematicForm
