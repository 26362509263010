import { Col, Typography } from 'antd'

const { Paragraph } = Typography

const FilterInputColumn = ({ children, span, title }) => (
	<Col span={span || 3}>
		{title && <Paragraph type='secondary'>{title}:</Paragraph>}
		{children}
	</Col>
)

export default FilterInputColumn
