import dayjs from 'dayjs'

export const schema_list = {
	id: { renderType: 'report', title: 'Quarter', showInputWhen: false, fixed: 'left', },
	project_id: { renderType: 'project', title: 'Project', required: true },
	version_id: { renderType: 'text', title: 'Version', showInputWhen: false, hideColumn: true, required: true },
	quarter: { 
		renderType: 'quarter',
		title: 'Quarter',
		required: true,
		hideColumn: true,
		formItemProps: {
			getValueProps: value => ({ value: value ? dayjs(value) : null }),
			getValueFromEvent: data => (data ? dayjs(data).format('YYYY-MM-DD') : null)
		}
	},
	material_url: { renderType: 'langUrl', showInputWhen: false },
	syndicator_summary: { renderType: 'textArea', showInputWhen: false },
	valuation: { renderType: 'textArea', showInputWhen: false },
	investment_events: { renderType: 'textArea', showInputWhen: false },
	cash_in_the_bank: { renderType: 'textArea', showInputWhen: false },
	burn_rate: { renderType: 'textArea', showInputWhen: false },
	run_way: { renderType: 'textArea', showInputWhen: false },
	revenue: { renderType: 'textArea', showInputWhen: false },
	ebitda: { renderType: 'textArea', showInputWhen: false },
	mrr: { renderType: 'textArea', showInputWhen: false },
	key_metrics: { renderType: 'textArea', showInputWhen: false },
}

const rules = [
	({ getFieldValue }) => {
		console.log(getFieldValue('quarter'))
		return ({
		required: getFieldValue('quarter') ? dayjs(getFieldValue('quarter')).isAfter('2024-01-01') : true,
		message: '',
	})},
]

export const schema_show = {
	published: { renderType: 'bool', title: 'Published', showInputWhen: false },
	report_id: { renderType: 'text', title: 'Report ID', showInputWhen: false, hideColumn: true },
	author: { renderType: 'text', title: 'Author', showInputWhen: false },
	material_url: { renderType: 'langUrl' },
	syndicator_summary: { renderType: 'textArea', rules },
	valuation: { renderType: 'number', rules, 
		formItemProps: {
			addonAfter:'$',
		}
	},
	investment_events: { renderType: 'textArea', rules },
	cash_in_the_bank: { renderType: 'number', rules,
		formItemProps: {
			addonAfter:'$',
		}
	},
	burn_rate: { renderType: 'number', rules,
		formItemProps: {
			addonAfter:'$',
		}
	},
	run_way: { renderType: 'textArea', rules },
	revenue: { renderType: 'number', rules,
		formItemProps: {
			addonAfter:'$',
		}
	},
	ebitda: { renderType: 'textArea', rules },
	mrr: { renderType: 'number', rules,
		formItemProps: {
			addonAfter:'$',
		}
	},
	key_metrics: { renderType: 'textArea', rules },
	created: {
		renderType: 'time',
		sorter: {},
		formItemProps: {
			getValueProps: value => ({ value: value ? dayjs(value) : null }),
			getValueFromEvent: data => (data ? dayjs(data).format('YYYY-MM-DD HH:mm:ss') : null)
		},
		showInputWhen: false,
	},
	quarter: {
		showInputWhen: false,
		hideColumn: true,
	}
}