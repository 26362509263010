import { getStartCase } from './helpers'

const transformArrayToOptions = (items, labelMapper = getStartCase) => items.map(item => ({ label: labelMapper(item), value: item }))
const transformObjectToOptions = items => Object.entries(items).map(([key, value]) => ({ label: getStartCase(key), value: key, ...value }))

export const currenciesOptions = [
	{ value: 'usd', label: '$' },
	{ value: 'rub', label: '₽' },
	{ value: 'aed', label: 'د.إ' },
	{ value: 'gbp', label: '£' }
].map(({ value, label }) => ({ value, label: `${label} (${value.toUpperCase()})` }))

export const dealTypesOptions = transformArrayToOptions(['deal', 'external_deal', 'fund', 'secret_deal'])

export const dealStages = {
	fund: {},
	other: {},
	pre_ipo: { label: 'Pre IPO' },
	pre_seed: {},
	pre_series_a: {},
	pre_series_b: {},
	pre_series_c: {},
	pre_series_d: {},
	seed: {},
	series_a: {},
	series_b: {},
	series_c: {},
	series_d: {},
	ipo: { label: 'IPO' },
	fff: { label: 'FFF' }
}

export const dealStagesOptions = transformObjectToOptions(dealStages)

export const dealStates = {
	new: {},
	submit_to_ic: { label: 'Submit to IC' },
	ic_approval: { label: 'IC Approval' },
	collect_commits: {},
	alloc_confirmation: {},
	confirm_allocation: {},
	completed: {},
	failed: {},
	rejected: {},
	particularly_closed: {}
}

export const dealStatesOptions = transformObjectToOptions(dealStates)

export const dealAccreditedForOptions = transformArrayToOptions(['us', 'eu', 'ru', 'asia', 'all'], s =>
	String.prototype.toUpperCase.call(s)
)
