import { ListEdit } from '../../listEdit'
import FilterCard from './wrappers/filterCard'

const FiltersSet = () => (
	<ListEdit name={['filters']} addLabel='Add filter'>
		<FilterCard />
	</ListEdit>
)

export default FiltersSet
