import { Form } from 'antd'

import StepsForm from '../stepsForm'

import { getStartCase } from '../../utils/helpers'

import Offer from './steps/offer'
import State from './steps/state'
import Content from './steps/content'
import Settings from './steps/settings'
import Conditions from './steps/conditions'

const { Item } = Form

const StepWrapper = ({ children }) => (
	<div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
		<div style={{ width: '1024px' }}>{children}</div>
	</div>
)

const wrapStep = c => <StepWrapper>{c}</StepWrapper>

function Step(key, children) {
	this.key = key
	this.title = getStartCase(key)
	this.content = wrapStep(children)
}

const items = [
	['settings', <Settings />],
	['content', <Content />],
	['offer', <Offer />],
	['conditions', <Conditions />],
	['state', <State />]
].map(i => new Step(...i))

const DealForm = props => <StepsForm {...props} items={items} />

export const getFormItems = (items, itemProps = {}) =>
	Object.entries(items).map(([key, value]) => {
		const [component, currentItemProps] = Array.isArray(value) ? value : [value, {}]

		return (
			<Item
				key={key}
				name={key}
				labelAlign='left'
				labelCol={{ span: 5 }}
				label={getStartCase(key)}
				{...itemProps}
				{...currentItemProps}
			>
				{component}
			</Item>
		)
	})

export const getFormItemsWithLabels = (items, itemProps = {}) =>
	Object.entries(items).map(([key, [component, label]]) => (
		<Item key={key} name={key} labelCol={{ span: 5 }} label={label} labelAlign='left' {...itemProps}>
			{component}
		</Item>
	))

export default DealForm
