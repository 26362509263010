import { getStartCase } from '../../utils/helpers'
import { validateMessages } from '../../utils/constants'

import Review from './steps/review'
import Content from './steps/content'
import Trigger from './steps/trigger'
import Context from './steps/context'
import Recipients from './steps/recipients'
import StepsForm from '../stepsForm'

function Step(key, content) {
	this.key = key
	this.content = content
	this.title = getStartCase(key)
}

export const requiredItemProps = { rules: [{ required: true, message: 'Field is required' }] }

const items = [
	['filter', <Recipients />],
	['content', <Content />],
	['trigger', <Trigger />],
	['context', <Context />],
	['review', <Review />]
].map(i => new Step(...i))

const CampaignForm = ({ formProps, ...props }) => {
	formProps = {
		...formProps,
		labelCol: { span: 2 },
		validateMessages: validateMessages
	}

	return <StepsForm formProps={formProps} {...props} items={items} />
}

export default CampaignForm
