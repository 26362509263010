import { Row, Form, InputNumber } from 'antd'

import { DateInput } from '../../inputs'
import FilterInputColumn from './wrappers/filterInputColumn'

const InvestmentFilter = ({ pathName }) => (
	<Row gutter={[16, 16]}>
		<FilterInputColumn title='Date from' span={6}>
			<DateInput name={[...pathName, 'date_from']} placeholder={null} />
		</FilterInputColumn>
		<FilterInputColumn title='Date to' span={6}>
			<DateInput name={[...pathName, 'date_to']} placeholder={null} />
		</FilterInputColumn>
		<FilterInputColumn title='Min Sum'>
			<Form.Item placeholder='Min Sum' name={[...pathName, 'min_sum']}>
				<InputNumber addonBefore='$' min={0} style={{ width: '100%' }} />
			</Form.Item>
		</FilterInputColumn>
		<FilterInputColumn title='Max Sum'>
			<Form.Item placeholder='Max Sum' name={[...pathName, 'max_sum']}>
				<InputNumber addonBefore='$' min={0} style={{ width: '100%' }} />
			</Form.Item>
		</FilterInputColumn>
		<FilterInputColumn title='Min Avg'>
			<Form.Item placeholder='Min Avg' name={[...pathName, 'min_avg']}>
				<InputNumber addonBefore='$' min={0} style={{ width: '100%' }} />
			</Form.Item>
		</FilterInputColumn>
		<FilterInputColumn title='Max Avg'>
			<Form.Item placeholder='Max Avg' name={[...pathName, 'max_avg']}>
				<InputNumber addonBefore='$' min={0} style={{ width: '100%' }} />
			</Form.Item>
		</FilterInputColumn>
	</Row>
)

export default InvestmentFilter
