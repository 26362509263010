import { Select } from 'antd'

import { getFlagEmoji, countriesByAlpha2 } from '../../utils/countries'

const CountrySelector = props => (
	<Select
		options={Object.entries(countriesByAlpha2).map(([key, value]) => ({
			label: `${getFlagEmoji(key)} ${value}`,
			value: key
		}))}
		filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
		mode='multiple'
		placeholder='Select countries'
		{...props}
	/>
)

export default CountrySelector
