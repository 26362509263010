import debounce from 'lodash.debounce'
import { useStepsForm } from '@refinedev/antd'

import useRecipientsCount from './useRecipientsCount'

const useCampaignFormProps = ({ initialValues, ignoreRecipientsUpdateWithoutCampId = false } = {}) => {
	const { current, gotoStep, stepsProps, formProps, saveButtonProps } = useStepsForm({
		redirect: 'show',
		successNotification: false
	})

	const [recipientsCount, updateRecipientsCount] = useRecipientsCount(formProps.initialValues?.id, {
		ignoreRecipientsUpdateWithoutCampId,
		initialFilters: initialValues?.filters
	})
	const debouncedUpdateRecipientsCount = debounce(filters => updateRecipientsCount(filters), 2000)

	const onValuesChangeRecipientsCountUpdate = (changedValues, allValues) => {
		formProps.onValuesChange(changedValues, allValues)

		if (changedValues?.filters) {
			debouncedUpdateRecipientsCount(allValues?.filters)
		}
	}

	if (initialValues) {
		formProps.initialValues = {
			...formProps.initialValues,
			...initialValues
		}
	}

	return {
		resource: {
			saveButtonProps,
			headerProps: {
				subTitle: `Recipients: ${recipientsCount}`
			},
			headerButtons: ({ defaultButtons }) => <>{defaultButtons}</>
		},
		form: {
			formProps: { ...formProps, onValuesChange: onValuesChangeRecipientsCountUpdate },
			current,
			gotoStep,
			stepsProps
		}
	}
}

export default useCampaignFormProps
