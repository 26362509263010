import { Card, Col, Row, Select } from 'antd'

import { DateInput } from '../../inputs'
import { getFormItems } from '../dealForm'

import { dealStatesOptions } from '../../../utils/options'

const styledDateInput = <DateInput style={{ marginBottom: 0 }} />

const columnItems = {
	state: <Select options={dealStatesOptions} />
}

const columnDeadlines = {
	date_announced: styledDateInput,
	commit_deadline: styledDateInput,
	transfer_deadline: styledDateInput,
	transfer_startup_deadline: styledDateInput
}

const State = () => (
	<>
		<Row gutter={[16, 16]} justify='center'>
			<Col span={14}>{getFormItems(columnItems, { labelCol: { span: 8 } })}</Col>
		</Row>
		<Row gutter={[16, 16]} justify='center'>
			<Col span={14}>
				<Card>{getFormItems(columnDeadlines, { labelCol: { span: 8 } })}</Card>
			</Col>
		</Row>
	</>
)

export default State
