import { SchematicDataTableWithCreateModal } from '../../schematicComponents'

import { dealInvestmentsSchema } from '../../../pages/investments/schema'

const DealInvestments = ({ record }) => (
	<SchematicDataTableWithCreateModal
		title='Investment'
		resource='investments'
		schema={dealInvestmentsSchema}
		modalFormProps={{
			initialValues: {
				deal_id: record.id
			},
			useFormParams: {
				redirect: false
			}
		}}
		dataTableProps={{
			filters: {
				permanent: [
					{
						field: 'deal_id',
						operator: 'eq',
						value: record.id
					}
				]
			},
			listProps: {
				breadcrumb: false,
				title: 'Investments'
			}
		}}
	/>
)

export default DealInvestments
