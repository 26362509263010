import { useShow } from '@refinedev/core'
import { Button, Form, Input, Space, Table, Typography } from 'antd'
import {
	List,
	Show,
	useTable,
	TextField,
	DateField,
	EditButton,
	ShowButton,
	NumberField,
	BooleanField,
	DeleteButton
} from '@refinedev/antd'
import dayjs from 'dayjs'

import { CopyButton } from './buttons'
import { langsByKey } from '../utils/constants'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
dayjs.extend(quarterOfYear)

const { Text } = Typography

const TEXT_AREA_RENDER_SUBSTRING_LENGTH = 64

const EmptyText = () => <Text style={{ color: 'grey' }}>—</Text>

export const formatCurrency = (value, currency = 'USD') =>
	typeof value === 'number'
		? new Intl.NumberFormat('en-US', {
				style: 'currency',
				maximumFractionDigits: 0,
				currencyDisplay: 'symbol',
				currency: typeof currency === 'string' ? currency : 'USD'
		  }).format(value)
		: '—'

export const getFormattedTextArea = value => {
	if (!value || typeof value !== 'string') return <EmptyText />

	const len = value.length
	const v = value.substring(0, TEXT_AREA_RENDER_SUBSTRING_LENGTH) + (len > TEXT_AREA_RENDER_SUBSTRING_LENGTH ? '...' : '')
	return <TextField value={v} />
}

const getNullableRenderer = renderer => (v, c) => v !== undefined ? renderer(v, c) : <EmptyText />

const commonRenderers = {
	percent: v => `${v.toFixed(2)}%`,
	text: v => <TextField value={v} />,
	bool: v => <BooleanField value={v} />,
	number: v => <NumberField value={v} />,
	date: v => <DateField format='ll' value={v} />,
	phone: v => <TextField value={v && `+${v}`} />,
	time: v => v && <DateField format='L LT' value={v} />,
	quarter: v => v && <TextField style={{ color: '#1677ff' }} value={v && `${dayjs(v).year()}-Q${dayjs(v).quarter()}`} />,
	currency: (v, c) => <TextField value={formatCurrency(v, c)} />,
	username: v => <TextField code copyable value={v && `@${v}`} />,
	content_langs: v => <TextField value={v && v?.['langs']?.map(lang => langsByKey[lang]?.label + ' ')} />,
	textArea: v => getFormattedTextArea(v)
}

export const renderers = Object.entries(commonRenderers).reduce(
	(acc, [key, renderer]) => ({ ...acc, [key]: getNullableRenderer(renderer) }),
	{}
)

export const DataTable = ({
	columns,
	actions,
	actionsColumnProps,
	customActions,
	listProps,
	search,
	query,
	rowClassName,
	onRow,
	...props
}) => {
	const searchColumnName = search && search === true ? 'search' : search

	const { tableProps, searchFormProps, tableQueryResult } = useTable({
		syncWithLocation: true,
		onSearch: values => [
			{
				field: searchColumnName,
				value: values[searchColumnName]
			}
		],
		filters: {
			permanent: query && [...query].filter(Boolean)
		},
		...props
	})

	return (
		<List {...listProps}>
			<Space direction='vertical' size='middle' style={{ width: '100%' }}>
				{searchColumnName && (
					<Form {...searchFormProps} layout='inline'>
						<Form.Item name={searchColumnName}>
							<Input.Search placeholder='Search' onSearch={searchFormProps.form?.submit} />
						</Form.Item>
					</Form>
				)}
				<Table onRow={onRow} {...tableProps} rowKey='id' rowClassName={rowClassName}>
					{columns.map(({ field, ...props }) => (
						<Table.Column key={field} dataIndex={field} {...props} />
					))}
					{(actions || customActions) && (
						<Table.Column
							title='Actions'
							dataIndex='actions'
							align='end'
							render={(_, record) => (
								<Space>
									{actions?.show && <ShowButton hideText size='small' recordItemId={record.id} />}
									{actions?.edit && <EditButton hideText size='small' recordItemId={record.id} />}
									{actions?.del && <DeleteButton hideText size='small' recordItemId={record.id} />}
									{actions?.copy && <CopyButton size='small' recordItemId={record.id} />}
									{customActions?.map(({ title, onClick, disabled, ...props }, ind) => (
										<Button
											key={ind}
											size='small'
											onClick={() => onClick(record.id, record, tableQueryResult.refetch)}
											disabled={props?.disabled ?? props?.isDisabled?.(record)}
											{...props}
										>
											{title}
										</Button>
									))}
								</Space>
							)}
							{...actionsColumnProps}
						/>
					)}
				</Table>
			</Space>
		</List>
	)
}

export const DataShow = ({ children }) => {
	const { queryResult } = useShow()
	const { data, isLoading } = queryResult
	const record = data?.data

	return (
		<Show isLoading={isLoading} headerProps={{ extra: <EditButton /> }}>
			{record && children(record)}
		</Show>
	)
}
