import {useApiUrl} from "@refinedev/core";
import {Avatar} from "antd";


export const ItemImage = ({path, id, field}) => {
  const apiUrl = useApiUrl();
  return <Avatar shape="square" src={`${apiUrl}${path}/${id}/${field}`}/>
}


export const tgName = (v) => v ? `@${v}` : ''
