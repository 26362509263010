import {useLogout} from "@refinedev/core";
import {useEffect} from 'react'
import axios from "axios";

export const axiosInstance = axios.create()
axiosInstance.interceptors.request.use((request) => {
    const token = localStorage.getItem("token");
    if (request.headers) {
        request.headers["Authorization"] = `Bearer ${token}`;
    } else {
        request.headers = {
            Authorization: `Bearer ${token}`,
        };
    }
    return request;
});


export const AxiosInterceptor = ({children}) => {
    const {mutate: logout} = useLogout();
    useEffect(() => {
        const resInterceptor = response => {
            return response;
        }

        const errInterceptor = error => {
            if (error.response?.status === 401) {
                logout();
            }
            return Promise.reject(error);
        }
        const interceptor = axiosInstance.interceptors.response.use(resInterceptor, errInterceptor);
        return () => axiosInstance.interceptors.response.eject(interceptor);

    }, [logout])

    return children;
}
