import { Row, Col, Form, Input, Space, Typography } from 'antd'

import FiltersSet from '../filters/filtersSet'
import { requiredItemProps } from '../campaignForm'
import { RemoteSelector } from '../../selectors/querySelectors'

const { Title } = Typography

const Details = () => (
	<Row>
		<Col span={12}>
			<Form.Item label='Name' name='name' labelCol={{ span: 0 }} {...requiredItemProps}>
				<Input />
			</Form.Item>
		</Col>
		<Col span={12}>
			<Form.Item label='Tags' name='tags' labelCol={{ span: 3 }}>
				<RemoteSelector path='/campaigns/tags' mode='tags' style={{ width: '100%' }} />
			</Form.Item>
		</Col>
	</Row>
)

const Recipients = () => {
	return (
		<>
			<Space direction='vertical' size='small' style={{ display: 'flex' }}>
				<Title level={3}>Details</Title>
				<Details />
			</Space>
			<Title level={3}>Filters</Title>
			<FiltersSet />
		</>
	)
}

export default Recipients
