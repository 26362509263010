import ReactQuill from 'react-quill'
import { Select, Tabs, Typography } from 'antd'

import { langs } from '../utils/constants'

const { Paragraph } = Typography

const modules = {
	toolbar: {
		container: [['bold', 'italic', 'underline', 'strike', 'blockquote'], ['link'], ['clean'], ['emoji']],
		handlers: {
			emoji: function (e) {
				console.log(e)
			}
		}
	},
	'emoji-toolbar': true
	// clipboard: {
	// 	matchVisual: false
	// }
}

const formats = ['bold', 'italic', 'underline', 'strike', 'blockquote', 'link']

export const TGTextEdit = props => <ReactQuill theme='snow' modules={modules} formats={formats} {...props} />

export const LangSelector = props => (
	<Select options={langs.map(({ key, label, text }) => ({ label: `${label} ${text}`, value: key }))} defaultValue={langs[0].key} {...props} />
)

export const I18TextEdit = ({ value, onChange }) => {
	const onTabChange = (k, v) => {
		onChange({ ...(value ?? {}), ...{ [k]: v } })
	}

	const items = langs.map(o => ({
		children: <TGTextEdit value={value?.[o.key]} onChange={v => onTabChange(o.key, v)} />,
		...o
	}))

	return <Tabs defaultActiveKey='en' items={items} />
}

export const I18TextView = ({ value }) => {
	const items = langs.map(o => ({
		children: (
			<Paragraph>
				<span dangerouslySetInnerHTML={{ __html: value?.[o.key] }} />
			</Paragraph>
		),
		...o
	}))

	return <Tabs defaultActiveKey='en' items={items} />
}

export default I18TextEdit
