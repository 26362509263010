import { useApiUrl, useCustomMutation, useTable } from '@refinedev/core'
import { SchematicDataTableWithCreateModal } from '../../components/schematicComponents'

import { schema_show } from './schema'
import { useParams, useSearchParams } from 'react-router-dom'
import { useCallback } from 'react'

const ProjectReportShow = () => {
	const { id } = useParams()
	const { mutate } = useCustomMutation()
	const apiUrl = useApiUrl()
	const [searchParams, setSearchParams] = useSearchParams()
	const quarter = searchParams.get('quarter')

	const query = [
		{
			field: 'report_id',
			operator: 'eq',
			value: id
		}
	]

	const {
		tableQueryResult: { refetch }
	} = useTable({
		resource: 'project_reports/versions',
		queryOptions: {
			enabled: false
		},
		filters: {
			permanent: [...query].filter(Boolean)
		}
	})

	const handlePublish = useCallback(
		(id, close) => {
			mutate(
				{
					url: `${apiUrl}/project_reports/versions/${id}/publish`,
					method: 'put',
					successNotification: {
						message: 'Version was published',
						type: 'success'
					},
					errorNotification: {
						message: 'Version was NOT published',
						type: 'error'
					}
				},
				{
					onSuccess: async () => {
						close()
						refetch()
					}
				}
			)
		},
		[mutate, apiUrl, refetch]
	)

	return (
		<SchematicDataTableWithCreateModal
			editable
			schema={schema_show}
			title={`Report versions`}
			resource={`project_reports/versions`}
			actions={{ del: false }}
			query={query}
			publish
			handlePublish={handlePublish}
			params={{ report_id: id }}
			modalFormProps={{
				initialValues: {
					quarter
				}
			}}
			dataTableProps={{
				onRow: record => {
					return {
						onClick: () => {
							if (record.quarter) {
								setSearchParams({ quarter: record.quarter })
							}
						}
					}
				}
			}}
		/>
	)
}

export default ProjectReportShow
