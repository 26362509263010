import { useEffect } from 'react'
import { Button, Card, Col, Form, InputNumber, Row, Space, Typography } from 'antd'

import { PercentInput } from '../../inputs'
import { getFormItemsWithLabels } from '../dealForm'

const { Item } = Form
const { Text } = Typography

const columnItems = {
	spv_fee: [<InputNumber style={{ width: '100%' }} />, 'SPV Fixed Fee'],
	min_commit_amount: [<InputNumber style={{ width: '100%' }} />, 'Minimum Commit Amount']
}

export const floatingValues = {
	sf_synd: 'Setup Fee Synd',
	sf_ad: 'Setup Fee AD',
	ci_synd: 'Carry Index Synd',
	ci_ad: 'Carry Index AD',
	mgmt_synd: 'Management Fee Synd',
	mgmt_ad: 'Management Fee AD'
}

const floatingValuesWithThreshold = { threshold: 'Threshold', ...floatingValues }

const CellWrapper = ({ children, style }) => <div style={{ height: 60, ...style }}>{children}</div>

const CellText = ({ value }) => (
	<CellWrapper style={{ paddingTop: 4 }}>
		<Text>{value}</Text>
	</CellWrapper>
)

const LastThresholdButtons = ({ fees }) => {
	const form = Form.useFormInstance()

	const addColumn = () => {
		form.setFieldValue('fees', [...fees, fees[fees.length - 1]])
	}

	const removeLastColumn = () => {
		if (fees.length === 1) return
		form.setFieldValue('fees', fees.slice(0, -1))
	}

	return (
		<CellWrapper>
			<Space.Compact>
				{fees.length > 1 && <Button onClick={removeLastColumn}>-</Button>}
				<Button onClick={addColumn}>+</Button>
			</Space.Compact>
		</CellWrapper>
	)
}

const FloatingConditionsInputs = () => {
	const form = Form.useFormInstance()

	const fees = Form.useWatch(['fees'], { preserve: true }) || [{}]
	const thresholdsCount = fees.length || null

	useEffect(() => {
		// todo: wtf
		if (!(fees?.length === 1 && JSON.stringify(fees[0]) === '{}')) {
			if (thresholdsCount !== null && thresholdsCount > 0) form.setFieldValue(['fees', thresholdsCount - 1, 'threshold'], null)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form, thresholdsCount])

	const firstColumn = (
		<Space direction='vertical' size='small' style={{ width: 200 }}>
			{Object.values(floatingValuesWithThreshold).map(v => (
				<CellText value={v} key={v} />
			))}
		</Space>
	)

	const columns = Array(thresholdsCount)
		.fill(0)
		.map((_, ind) => (
			<Space direction='vertical' size='small' key={ind} style={{ width: 120 }}>
				{Object.keys(floatingValuesWithThreshold).map(key =>
					key === 'threshold' && ind + 1 === thresholdsCount ? (
						<LastThresholdButtons fees={fees} key={key} />
					) : (
						<Item name={['fees', ind, key]} label={null} style={{ height: 60, marginBottom: 0 }} key={[ind, key].join()}>
							{key === 'threshold' ? <InputNumber style={{ width: 100 }} /> : <PercentInput style={{ width: 100 }} />}
						</Item>
					)
				)}
			</Space>
		))

	return (
		<Space>
			{firstColumn}
			{columns}
		</Space>
	)
}

const Conditions = () => {
	return (
		<Space direction='vertical' size='large' style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
			<Card>
				<FloatingConditionsInputs />
			</Card>
			<Row gutter={[64, 16]} justify='center'>
				{getFormItemsWithLabels(columnItems, { labelCol: { span: 14 } }).map((i, ind) => (
					<Col span={12} key={ind}>
						{i}
					</Col>
				))}
			</Row>
		</Space>
	)
}

export default Conditions
