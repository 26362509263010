import ProjectReportsList from './list'
import ProjectReportShow from './show'


const projectReportsPages = {
	list: ProjectReportsList,
	show: ProjectReportShow
}

export default projectReportsPages
