import { Card, Form, Space, Typography } from 'antd'

import CampaignView from '../campaignView'

const { Title } = Typography

const Review = () => {
	const form = Form.useFormInstance()
	const camp = form.getFieldsValue(true)

	return (
		<Space direction='vertical' size='small' style={{ display: 'flex' }}>
			<Title level={3}>Review</Title>
			<Card>
				<CampaignView camp={camp} />
			</Card>
		</Space>
	)
}

export default Review
