import { Space, Tabs, Typography } from 'antd'
import { green, blue } from '@ant-design/colors'

import TagsRow from '../../containers/tagsRow'
import { DataShow } from '../../components/dataView'

import { getStartCase } from '../../utils/helpers'
import { dealStages, dealStates } from '../../utils/options'

import dealSchema from './schema'

import DealOffer from '../../components/deals/tabs/offer'
import DealSummary from '../../components/deals/tabs/summary'
import DealContent from '../../components/deals/tabs/content'
import DealConditions from '../../components/deals/tabs/conditions'
import DealInvestments from '../../components/deals/tabs/investments'

const { getTag } = TagsRow
const { Title, Link } = Typography

const Tab = function (key, children) {
	this.key = key
	this.children = children
	this.label = getStartCase(key)
}

const getTabs = tabProps =>
	[
		['summary', <DealSummary {...tabProps} />],
		['offer', <DealOffer {...tabProps} />],
		['conditions', <DealConditions {...tabProps} />],
		['content', <DealContent {...tabProps} />],
		// ['state', null],
		['investments', <DealInvestments {...tabProps} />]
	].map(t => new Tab(...t))

const DealHeader = ({ record: { industry_names, type, stage, state, project_name, project_id } }) => (
	<div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
		<Space size='small'>
			<TagsRow tags={industry_names?.map(i => getTag(i, green[5]))} />
			<TagsRow
				tags={[
					getTag(getStartCase(type)),
					getTag(dealStages[stage]?.label || getStartCase(stage), blue[5]),
					getTag(dealStates[state]?.label || getStartCase(state), dealSchema.state.renderSchema[state]?.color || 'default')
				]}
			/>
		</Space>
		<Link href={`/projects/${project_id}`} target='_blank'>
			{project_name}
		</Link>
	</div>
)

const DealShow = () => {
	return (
		<DataShow>
			{record =>
				record && (
					<Space direction='vertical' size='middle' style={{ width: '100%' }}>
						<DealHeader record={record} />
						<Title level={3} style={{ margin: 0 }}>
							{`${record.name} ${record.number}`}
						</Title>
						<Tabs items={getTabs({ record })} />
					</Space>
				)
			}
		</DataShow>
	)
}

export default DealShow
