import { Edit } from '@refinedev/antd'
import { Space, Typography } from 'antd'
import { useOne, useResource } from '@refinedev/core'

import CampaignForm from '../../components/campaign/campaignForm'
import ActiveButton from '../../components/campaign/buttons/activeButton'
import useCampaignFormProps from '../../components/campaign/hooks/useCampaignFormProps'

const { Text } = Typography

const CampaignActive = ({ id, name, active }) => (
	<Space direction='vertical' size='large'>
		<Text>Campaign is active now</Text>
		<ActiveButton campaignId={id} campaignName={name} campaignActive={active} />
	</Space>
)

const CampaignEdit = () => {
	const { resource, form } = useCampaignFormProps({ ignoreRecipientsUpdateWithoutCampId: true })
	const { id } = useResource()

	const { data: { data: { name, active } = {} } = {} } = useOne({
		resource: 'campaigns',
		id: id
	})

	return <Edit {...resource}>{active ? <CampaignActive id={id} name={name} active={active} /> : <CampaignForm {...form} />}</Edit>
}

export default CampaignEdit
