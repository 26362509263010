import { Typography, Tag, Space } from 'antd'

import { getDefaultTitle } from '.'
import { ItemImage } from '../../other'
import { renderers } from '../../dataView'
import { getFlagEmoji } from '../../../utils/countries'
import { NavLink } from 'react-router-dom'

const { Link } = Typography

const linkRender = (title, link) => <NavLink to={link}>{title}</NavLink>
const projectRender = (id, { project_name }) => linkRender(project_name, `/projects/show/${id}`)
const reportRender = (id, { quarter }) => linkRender(renderers.quarter(quarter), `/project_reports/show/${id}`)
const dealRender = (id, { deal_name: name, deal_number: num }) => linkRender([name, num].join(' '), `/deals/show/${id}`)
const investorRender = (id, { investor_first_name: f, investor_last_name: l, ext_investor }) =>
	ext_investor || linkRender([f, l].join(' '), `/investors/show/${id}`)
const langUrlRender = langUrl =>
	langUrl && (
		<Space size='middle'>
			{Object.entries(langUrl).map(([lang, url]) => (
				<Link href={url}>
					{getFlagEmoji(lang)} {url}
				</Link>
			))}
		</Space>
	)

const getTagRender = schema => v => {
	const { color, title } = typeof schema === 'string' ? { color: schema } : schema?.[v] || {}
	return (
		v && (
			<Tag color={color} key={v}>
				{title || getDefaultTitle(v)}
			</Tag>
		)
	)
}
const getTagsRender = schema => v => v?.map(getTagRender(schema)) || []
const getItemImageRender = schema => id => <ItemImage path={schema?.path} field={schema?.field} id={id} />
const getCurrencyRender = (_, field) => (value, formData) => renderers.currency(value, formData[`${field}_currency`])

const columnRendersGettersByType = {
	currency: (schema, field) => getCurrencyRender(schema, field),
	itemImage: schema => getItemImageRender(schema),
	industries: schema => getTagsRender(schema),
	tags: schema => getTagsRender(schema),
	tag: schema => getTagRender(schema),
	langUrl: () => langUrlRender,
	investor: () => investorRender,
	project: () => projectRender,
	report: () => reportRender,
	deal: () => dealRender,
	content_langs: () => renderers.content_langs,
	tgUsername: () => renderers.username,
	textArea: () => renderers.textArea,
	percent: () => renderers.percent,
	number: () => renderers.number,
	phone: () => renderers.phone,
	text: () => renderers.text,
	date: () => renderers.date,
	time: () => renderers.time,
	quarter: () => renderers.quarter,
	bool: () => renderers.bool,
	lang: () => renderers.lang
}

const getColumnRender = (type = 'text', schema, field) => columnRendersGettersByType[type]?.(schema, field)

const getTableColumn = (field, { renderType, renderSchema, hideColumn, ...props }) =>
	!hideColumn && {
		field,
		width: 200,
		ellipsis: true,
		title: getDefaultTitle(field),
		render: getColumnRender(renderType, renderSchema, field),
		...(props || {})
	}

const getTableColumns = customSchema =>
	Object.entries(customSchema || {})
		.map(c => getTableColumn(...c))
		.filter(Boolean)

export default getTableColumns
