import { useForm } from '@refinedev/antd'
import { Button, Modal } from 'antd'

import SchematicForm from './form'
import { useOne } from '@refinedev/core'
import { useEffect, useState } from 'react'

// edit when id is passed, create otherwise
const SchematicModalForm = ({
	schema,
	title,
	open,
	onCancel,
	resource,
	id,
	footerButtons,
	initialValues,
	useFormParams,
	onOkShow = true,
	publish = false,
	handlePublish,
	params = {},
	...props
}) => {
	const [okBtnText, setOkBtnText] = useState('')
	const [isTouched, setIsTouched] = useState(false)

	const {
		form,
		formProps: { onFinish, onFieldsChange, ...formProps },
		saveButtonProps,
		queryResult
	} = useForm({
		id,
		resource,
		action: id && !publish ? 'edit' : 'create',
		onMutationSuccess: () => {
			form.resetFields()
			close()
		},
		...useFormParams
	})

	const isPublished = queryResult?.data?.data?.published

	const updateForm = () => {
		setIsTouched(true)
	}

	const close = () => {
		setIsTouched(false)
		onCancel()
	}

	useEffect(() => {
		if (publish) {
			if (id) {
				setOkBtnText(isTouched ? 'Create & Publish' : 'Publish')
			} else {
				setOkBtnText('Create')
			}
		} else {
			if (id) {
				setOkBtnText('Edit')
			} else {
				setOkBtnText('Create')
			}
		}
	}, [id, publish, form, isTouched])

	const { data: initial } = useOne({
		resource,
		id,
		queryOptions: {
			enabled: Boolean(id) && publish
		}
	})

	formProps.onFinish = () => onFinish({ ...form.getFieldsValue(true), ...params })

	formProps.initialValues = {
		...formProps.initialValues,
		initialValues
	}

	const footer = [
		...(footerButtons?.map(({ title, ...btnProps }) => (
			<Button key={title} {...btnProps}>
				{title}
			</Button>
		)) || []),
		<Button onClick={close} key='cancel'>
			Cancel
		</Button>,
		onOkShow &&
			(publish && !isTouched && id ? (
				<Button
					type='primary'
					disabled={isPublished}
					onClick={() => {
						handlePublish(id, close)
					}}
				>
					Publish
				</Button>
			) : (
				<Button {...saveButtonProps} key='ok' type='primary'>
					{okBtnText}
				</Button>
			))
	]

	return (
		<Modal open={open} forceRender={true} onCancel={close} title={`${id ? 'Edit' : 'Create'} ${title}`} footer={footer} {...props}>
			<SchematicForm
				{...{
					schema,
					form,
					formProps: {
						onFieldsChange: updateForm,
						...formProps,
						initialValues: id ? formProps.initialValues : initialValues || {}
					}
				}}
			/>
		</Modal>
	)
}

export default SchematicModalForm
