import { useState, useEffect, useMemo } from 'react'
import { useApiUrl, useResource } from '@refinedev/core'
import { Form, Row, Col, Input, InputNumber, Select, Switch, Upload, Image } from 'antd'

import { getFormItems } from '../dealForm'
import { ProjectSelector } from '../../selectors/entitySelectors'

import { dealStagesOptions, dealTypesOptions } from '../../../utils/options'

const { Dragger } = Upload

const getBase64 = file =>
	new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve(reader.result)
		reader.onerror = error => reject(error)
	})

const Number = props => <InputNumber style={{ width: '100%' }} {...props} />

const firstColumnItems = {
	name: <Input />,
	number: <Input />,
	number_id: [<Number />, { label: 'Number ID' }],
	type: <Select options={dealTypesOptions} />,
	stage: <Select options={dealStagesOptions} />,
	url: <Input />
}

const secondColumnItems = {
	project_id: [<ProjectSelector />, { label: 'Project' }],
	ad_series: [<Switch />, { label: 'AD Series', valuePropName: 'checked', initialValue: false }]
	// url: <Input />
	// chat_link: <Input />,
	// accredited_for: <Select options={dealAccreditedForOptions} />,
	// expected_multiple: <Number style={{ width: '100%' }} />
}

const LogoUploader = ({ id, fileList, setFileList }) => {
	const apiUrl = useApiUrl()

	const beforeUploadHandler = async file => {
		file.url = await getBase64(file)
		setFileList([file])
	}

	return (
		<Dragger
			maxCount={1}
			multiple={false}
			action={getBase64}
			fileList={fileList}
			showUploadList={false}
			style={{ padding: 32 }}
			beforeUpload={beforeUploadHandler}
		>
			<Image preview={false} src={fileList?.[0]?.url || `${apiUrl}/deals/${id}/logo`} />
		</Dragger>
	)
}

const Settings = () => {
	const { id } = useResource()
	const form = Form.useFormInstance()

	const [logoFileList, logoSetFileList] = useState()
	const logo = useMemo(() => logoFileList?.length && logoFileList[0].url.split(',')[1], [logoFileList])

	useEffect(() => {
		if (logo) {
			form.setFieldValue(['logo'], logo)
		}
	}, [form, logo])

	return (
		<>
			<Row gutter={32} justify='center'>
				<Col span={6} style={{ padding: '0 32px 32px 0' }}>
					<LogoUploader id={id} fileList={logoFileList} setFileList={logoSetFileList} />
				</Col>
				<Col span={12}>{getFormItems(firstColumnItems, { rules: [{ required: true, message: '' }] })}</Col>
			</Row>
			<Row gutter={32} justify='center'>
				<Col span={18}>{getFormItems(secondColumnItems, { labelCol: { span: 5 }, rules: [{ required: true, message: '' }] })}</Col>
			</Row>
		</>
	)
}

export default Settings
