import { Form, Space, Typography, Radio } from 'antd'
import { SendOutlined, FieldTimeOutlined, HistoryOutlined, EyeOutlined } from '@ant-design/icons'

import EventTriggers from '../triggers/eventTriggers'
import CronTrigger from '../triggers/cronTrigger'
import TimeTrigger from '../triggers/timeTrigger'

const { Title } = Typography

export const triggerTypes = [
	{
		label: 'Manual',
		value: 'manual',
		Icon: SendOutlined
	},
	{
		label: 'Time',
		value: 'time',
		Icon: FieldTimeOutlined
	},
	{
		label: 'Cron schedule',
		value: 'cron',
		Icon: HistoryOutlined
	},
	{
		label: 'Event',
		value: 'event',
		Icon: EyeOutlined
	}
]

const inputs = {
	time: TimeTrigger,
	cron: CronTrigger,
	event: EventTriggers
}

const Trigger = () => {
	const form = Form.useFormInstance()
	const triggerType = Form.useWatch(['trigger_type'], form)
	const TriggerComp = inputs[triggerType]

	return (
		<>
			<Space direction='vertical' size='small' style={{ display: 'flex' }}>
				<Title level={3}>Trigger</Title>
				<Form.Item name={'trigger_type'}>
					<Radio.Group defaultValue='manual' buttonStyle='solid'>
						{triggerTypes.map(({ label, value, Icon }) => (
							<Radio.Button key={value} value={value} label={label}>
								<Space>
									<Icon />
									{label}
								</Space>
							</Radio.Button>
						))}
					</Radio.Group>
				</Form.Item>
			</Space>
			{TriggerComp && <TriggerComp />}
		</>
	)
}

export default Trigger
