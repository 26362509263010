import { useState, useEffect } from 'react'

import SchematicModalForm from './modalForm'
import SchematicDataTable from './dataTable'

import { getStartCase } from '../../utils/helpers'
import { EditOutlined } from '@ant-design/icons'

const SchematicDataTableWithCreateModal = ({
	resource,
	schema,
	title,
	createButtonTitle,
	actions,
	editable,
	modalFormProps,
	dataTableProps,
	customActions,
	publish,
	handlePublish,
	params,
	query
}) => {
	const [isEditorModalOpen, setIsEditorModalOpen] = useState(false)
	const [selectedInvestEventId, setSelectedInvestEventId] = useState()

	useEffect(() => {
		if (!isEditorModalOpen) {
			setSelectedInvestEventId()
		}
	}, [isEditorModalOpen])

	const editHandler = id => {
		setSelectedInvestEventId(id)
		setIsEditorModalOpen(true)
	}

	return (
		<>
			<SchematicModalForm
				{...modalFormProps}
				schema={schema}
				resource={resource}
				open={isEditorModalOpen}
				id={selectedInvestEventId}
				title={title || getStartCase(resource)}
				onCancel={() => setIsEditorModalOpen(false)}
				publish={publish}
				handlePublish={handlePublish}
				params={params}
			/>
			<SchematicDataTable
				{...dataTableProps}
				schema={schema}
				resource={resource}
				query={query}
				actionsColumnProps={{ fixed: 'right' }}
				actions={actions || { del: true, show: true }}
				customActions={
					editable && [
						{
							title: <EditOutlined style={{ transform: 'scale(1.143)' }} />,
							onClick: editHandler,
							style: {
								padding: '0px 5px'
							}
						},
						...(customActions ?? [])
					]
				}
				listProps={{
					title: title || getStartCase(resource),
					createButtonProps: { onClick: () => setIsEditorModalOpen(true), title: createButtonTitle },
					...(dataTableProps?.listProps || {})
				}}
			/>
		</>
	)
}

export default SchematicDataTableWithCreateModal
