import dayjs from 'dayjs'
import {blue, green, red} from '@ant-design/colors'

// schema for table columns and form's inputs
// possible values for selects are taken from possibleValues / renderSchema keys / renderType
// 'showInputWhen' is used to show/hide fields inputs based on other fields' values (showInputWhen: { field: 'cap_type', includes: ['post'] }); to hide pass false
// 'hideColumn' is used to hide columns in table
const schema = {
    type: {
        sorter: {},
        fixed: 'left',
        required: true,
        renderType: 'tag',
        renderSchema: {
            deal: {color: green[5]},
            fund: {color: green[5]},
            write_off: {color: blue[5]},
            exit: {color: red[5]},
            external_deal: {color: 'default'},
            revenue_report: {color: 'default'},
            negative_prediction: {color: 'default'},
            m_and_a: {color: 'default'}
        }
    },
    deal_id: {
        title: 'Deal',
        fixed: 'left',
        required: true,
        renderType: 'deal',
        showInputWhen: {field: 'type', includes: ['deal', 'fund']}
    },
    round_type: {
        sorter: {},
        title: 'Round',
        renderType: 'tag',
        showInputWhen: {field: 'type', includes: ['deal', 'external_deal', 'm_and_a']},
        renderSchema: {
            safe: {title: 'SAFE', color: 'green'},
            equity: {color: 'blue'},
            convertible_note: {color: 'red'},
            sla: {color: 'orange'},
            token_sale: {color: 'purple'}
        }
    },
    round_size: {
        sorter: {},
        renderType: 'currency',
        renderSchema: {currencyInput: true},
        showInputWhen: {field: 'type', includes: ['deal', 'external_deal', 'fund']}
    },
    project_id: {
        sorter: {},
        required: true,
        title: 'Project',
        renderType: 'project',
        formField: 'project_id',
        showInputWhen: {
            field: 'type',
            includes: ['external_deal', 'exit', 'write_off', 'revenue_report', 'm_and_a', 'negative_prediction']
        }
    },
    valuation_cap_type: {
        required: true,
        title: 'CAP Type',
        renderType: 'tag',
        renderSchema: 'orange',
        possibleValues: ['pre', 'post'],
        showInputWhen: {field: 'round_type', includes: ['safe', 'convertible_note']}
    },
    valuation_cap: {
        title: 'CAP',
        renderType: 'currency',
        renderSchema: {currencyInput: true},
        showInputWhen: {field: 'round_type', includes: ['safe', 'convertible_note']}
    },
    post_valuation: {
        renderType: 'currency',
        renderSchema: {currencyInput: true},
        showInputWhen: {field: 'round_type', includes: ['equity', 'negative_prediction', 'fund']}
    },
    discount_pct: {renderType: 'percent', showInputWhen: {field: 'round_type', includes: ['safe', 'convertible_note']}},
    // interest_rate: { renderType: 'percent', showInputWhen: { field: 'type', includes: ['deal', 'external_deal'] } },
    // synd_share_pct: { renderType: 'percent', showInputWhen: false },
    // total_shares: { renderType: 'currency', showInputWhen: { field: 'type', includes: ['deal', 'external_deal'] } },
    // synd_shares: { renderType: 'currency', showInputWhen: { field: 'type', includes: ['deal', 'external_deal'] } },
    investor: {renderType: 'text', showInputWhen: {field: 'type', includes: ['external_deal', 'm_and_a']}},
    // investor_id: { title: 'Investor', showInputWhen: { field: 'type', includes: ['deal'] }, renderType: 'investor' },
    // ext_investor: { title: 'Ext Investor', hideColumn: true, showInputWhen: { field: 'type', includes: ['external_deal'] }, renderType: 'text' },
    date: {
        sorter: {},
        required: true,
        renderType: 'date',
        formItemProps: {
            getValueProps: value => ({value: value ? dayjs(value) : null}),
            getValueFromEvent: data => (data ? dayjs(data).format('YYYY-MM-DD') : null)
        },
        showInputWhen: {
            field: 'type', includes: ['external_deal', 'revenue_report', 'm_and_a', 'negative_prediction',
                'fund']
        }
    },
    arr: {
        title: 'ARR',
        renderType: 'currency',
        renderSchema: {currencyInput: true},
        showInputWhen: {field: 'type', includes: ['revenue_report']}
    },
}

export const dealFormInvestEventSchema = Object.fromEntries(Object.entries(schema).filter(([k]) => k !== 'deal_id'))

export default schema
