import { SchematicDataTable } from '../../components/schematicComponents'

import dealSchema from './schema'

const DealList = () => (
	<SchematicDataTable
		search
		title='Deal'
		resource='deals'
		schema={dealSchema}
		actionsColumnProps={{ fixed: 'right' }}
		actions={{ show: true, edit: true, del: true }}
		sorters={{
			initial: [
				{
					field: 'created',
					order: 'desc'
				}
			]
		}}
	/>
)

// <>
// 	<SchematicModalForm
// 		title='Project'
// 		resource='projects'
// 		schema={projectSchema}
// 		open={isProjectModalOpen}
// 		useFormParams={{ redirect: false }}
// 		onCancel={() => setIsProjectModalOpen(false)}
// 	/>
// 	<SchematicDataTableWithCreateModal
// 		title='Deal'
// 		resource='deals'
// 		schema={dealSchema}
// 		actions={{ show: true, edit: true, del: true }}
// 		modalFormProps={{
// 			footerButtons: [{ title: 'Create project', onClick: () => setIsProjectModalOpen(true) }],
// 			zIndex: 5
// 		}}
// 	/>
// </>

export default DealList
