import keyBy from 'lodash.keyby'

export const langs = [
	{ key: 'en', label: '🇬🇧', text: 'English' },
	{ key: 'ru', label: '🇷🇺', text: 'Русский' }
].map(l => ({ ...l, labeled_text: `${l.label} ${l.text}` }))

export const langsByKey = keyBy(langs, 'key')

export const validateMessages = {
	// eslint-disable-next-line no-template-curly-in-string
	required: '${label} is required'
}

export const profileFields = [
	{ value: 'last_name', label: 'Last Name' },
	{ value: 'first_name', label: 'First Name' },
	{ value: 'alt_last_name1', label: 'Alt Last Name' },
	{ value: 'alt_first_name1', label: 'Alt First Name' },
	{ value: 'country', label: 'Country', input: 'country' },
	{ value: 'city', label: 'City' },
	{ value: 'birthday', label: 'Birthday', input: 'date' },
	{ value: 'email', label: 'Email' },
	{ value: 'company', label: 'Company' },
	{ value: 'position', label: 'Position' },
	{ value: 'website', label: 'Website' },
	{ value: 'recommender_id', label: 'Recommender', input: 'investor' },
	{ value: 'investing_experience', label: 'Investing Experience' },
	{ value: 'knowledge', label: 'Knowledge' },
	{ value: 'ready_to_invest', label: 'Ready To Invest' },
	{ value: 'help', label: 'Help' },
	{ value: 'accredited', label: 'Accredited' },
	{ value: 'invest_restrictions', label: 'Invest Restrictions' },
	{ value: 'citizenship', label: 'Citizenship', input: 'country' },
	{ value: 'invest_stage', label: 'Invest Stage' },
	{ value: 'invest_sum', label: 'Invest Sum' },
	{ value: 'education', label: 'Education' },
	{ value: 'expertise', label: 'Expertise' },
	{ value: 'bizclubs', label: 'Bizclubs' },
	{ value: 'hobby_categories', label: 'Hobby Categories' },
	{ value: 'socials', label: 'Socials' },
	{ value: 'bio', label: 'Bio' },
	{ value: 'loyalty_statuses', label: 'Loyalty Statuses' },
	{ value: 'filled_by', label: 'Filled By' },
	{ value: 'membership_fees_contract_date', label: 'Membership Fees Contract Date', input: 'date' },
	{ value: 'coin_balance', label: 'Coin Balance', input: 'number' },
	{ value: 'state_transition_elapsed_days', label: 'State Transition Elapsed Days', input: 'number' },
	{ value: 'spoken_langs', label: 'Spoken Langs' },
	{ value: 'showcase_langs', label: 'Showcase Langs' }
]
const getFieldsValuesByInputType = input => profileFields.filter(f => f.input === input).map(f => f.value)
export const profileFieldsDates = getFieldsValuesByInputType('date')
export const profileFieldsNumbers = getFieldsValuesByInputType('number')
export const profileFieldsCountry = getFieldsValuesByInputType('country')
export const profileFieldsInvestor = getFieldsValuesByInputType('investor')

export const selectStates = [
	{ label: 'Welcome', value: 'welcome' },
	{ label: 'Profile 1', value: 'profile1' },
	{ label: 'Moderation 1', value: 'moderation1' },
	{ label: 'Wait calendly', value: 'wait_calendly' },
	{ label: 'Calendly picked', value: 'calendly_picked' },
	{ label: 'Profile 2', value: 'profile2' },
	{ label: 'Moderation 2', value: 'moderation2' },
	{ label: 'KYC', value: 'kyc' },
	{ label: 'KYC approved', value: 'kyc_approved' },
	{ label: 'NDA', value: 'nda' },
	{ label: 'NDA signed', value: 'nda_signed' },
	{ label: 'Approved', value: 'approved' },
	{ label: 'Left club', value: 'left_club' }
]

export const commitTypes = ['hard', 'soft'].map(t => ({ value: t }))
