import dayjs from 'dayjs'
import { blue } from '@ant-design/colors'
import { DateField } from '@refinedev/antd'
import { PlayCircleFilled, QuestionCircleFilled } from '@ant-design/icons'
import { Col, Divider, List, Row, Space, Statistic, Tag, Typography } from 'antd'

import { triggerTypes } from './steps/trigger'
import { langsByKey } from '../../utils/constants'
import { eventTypes } from './triggers/eventTriggers'
import LangContent from '../../containers/langContent'
import { getCronTriggerInfo } from './triggers/cronTrigger'
import useRecipientsCount from './hooks/useRecipientsCount'

const { Title, Paragraph } = Typography

const RecipientsStatistic = ({ campId }) => {
	const [recipientsCount] = useRecipientsCount(campId)

	return <Statistic title='Recipients' value={recipientsCount} />
}

const triggerInfoRenderers = {
	event: info =>
		info
			?.map(i => eventTypes.find(e => e.value === i.type)?.label || null)
			.filter(Boolean)
			.join(', '),
	cron: info => getCronTriggerInfo(info),
	time: info => dayjs(info).format('ll HH:mm:ss')
}

const triggerInfoRender = (trigger, triggerInfo) => {
	console.log(triggerInfo)
	const func = triggerInfoRenderers[trigger]
	const res = func && triggerInfoRenderers[trigger](triggerInfo)
	return res && `(${res})`
}

const TriggerInfo = ({ trigger, campTrigger }) => {
	const triggerInfo = campTrigger?.[trigger.value === 'event' ? 'events' : trigger.value]

	return (
		<Space align='start'>
			{trigger?.Icon && <trigger.Icon />}
			{trigger?.label}
			{trigger && <Paragraph>{triggerInfoRender(trigger.value, triggerInfo)}</Paragraph>}
		</Space>
	)
}

const CampaignViewHeader = ({ camp }) => {
	const trigger = triggerTypes.find(t => t.value === camp?.trigger_type)

	return (
		<Row gutter={[32, 16]}>
			<Col>
				<Space align='start'>
					<Paragraph type='secondary'>Last updated</Paragraph>
					<DateField value={camp?.updated} format='ll' />
				</Space>
			</Col>
			<Col>
				<TriggerInfo trigger={trigger} campTrigger={camp?.trigger} />
			</Col>
			<Col>
				<Row gutter={[8, 8]}>{camp?.content?.langs && camp.content.langs.map(lang => <Col key={lang}>{langsByKey[lang]?.label}</Col>)}</Row>
			</Col>
			<Col>{camp?.active ? <Tag color='green'>Active</Tag> : <Tag color='gray'>Inactive</Tag>}</Col>
		</Row>
	)
}

const CampaignViewContentActions = ({ actions }) => {
	const lang = LangContent.useLang()

	return (
		actions &&
		lang && (
			<List
				size='small'
				header={
					<Title level={5} style={{ marginBottom: 0 }}>
						Actions
					</Title>
				}
				bordered
				dataSource={actions}
				renderItem={item => (
					<List.Item>
						<Space align='start'>
							{item?.type === 'quiz_answer' ? (
								<QuestionCircleFilled style={{ color: blue.primary }} />
							) : (
								<PlayCircleFilled style={{ color: blue.primary }} />
							)}
							<Paragraph style={{ margin: 0 }}>{item?.text?.[lang]}</Paragraph>
						</Space>
					</List.Item>
				)}
			/>
		)
	)
}

const CampaignViewContent = ({ camp }) => (
	<LangContent content={camp?.content?.message_text}>
		<CampaignViewContentActions actions={camp?.content?.actions} />
	</LangContent>
)

const CampaignView = ({ camp, withHeader, withRecipients }) => (
	<>
		<Space direction='vertical' size='small' style={{ display: 'flex' }}>
			{withHeader && <CampaignViewHeader camp={camp} />}
			<Title level={3}>{camp?.name}</Title>
			{withRecipients && <RecipientsStatistic campId={camp?.id} />}
		</Space>
		<Divider />
		<CampaignViewContent camp={camp} />
	</>
)

export default CampaignView
