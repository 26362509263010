import { Select } from 'antd'
import { useApiUrl, useCustom } from '@refinedev/core'

const getRemoteSelectorOptions = data => data?.data.map(s => ({ value: typeof s === 'boolean' ? (s ? 'No' : 'Yes') : s }))
const getQuerySelectorOptions = data => data?.data.rows.filter(s => s.name).map(s => ({ label: s.name, value: s.id }))

const useQuerySelectorOptions = ({ path, query, optionsGetter = getQuerySelectorOptions }) => {
	const apiUrl = useApiUrl()
	const { data, isLoading } = useCustom({
		url: `${apiUrl}${path}`,
		method: 'get',
		config: {
			query
		}
	})
	const options = optionsGetter(data)

	return {
		options,
		isLoading,
		selectorProps: {
			options,
			allowClear: true,
			showSearch: true,
			style: { width: '100%' },
			loading: isLoading
		}
	}
}

export const RemoteSelector = ({ path, query, ...props }) => {
	const { options, isLoading } = useQuerySelectorOptions({
		path: path,
		query,
		optionsGetter: getRemoteSelectorOptions
	})

	return <Select allowClear style={{ width: '100%' }} options={options} loading={isLoading} {...props} />
}

export const GroupChatsSelector = props => {
	const { selectorProps } = useQuerySelectorOptions({ path: '/group_chats' })

	return <Select {...selectorProps} {...props} />
}

export const SyndSelector = props => {
	const { selectorProps } = useQuerySelectorOptions({ path: '/legal_entities', query: { has_deals: true } })

	return <Select placeholder='Select syndicator' {...selectorProps} {...props} />
}

export const CampaignAnswerSelector = ({ campaign_id, ...props }) => {
	const { selectorProps } = useQuerySelectorOptions({
		path: `/campaigns/${campaign_id}`,
		optionsGetter: d => d?.data?.content?.actions?.map(a => ({ label: Object.values(a.text)[0], value: a.id }))
	})

	return <Select placeholder='Select answer' {...selectorProps} {...props} />
}
