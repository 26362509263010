import { Card, Space } from 'antd'

import LangWrapper from '../../../containers/langWrapper'
import { WrappedLangContent } from '../../../containers/langContent'

import { getStartCase } from '../../../utils/helpers'
import { contentFields, contentFieldsLabels } from '../../../pages/deals'

const LangContentField = ({ record, field }) =>
	record &&
	field && (
		<Card size='small' title={contentFieldsLabels[field] || getStartCase(field)}>
			<WrappedLangContent content={record[field]} />
		</Card>
	)

const DealContent = ({ record }) => (
	<LangWrapper>
		<Space direction='vertical' size='middle' style={{ width: '100%' }}>
			{contentFields.map(f => (
				<LangContentField key={f} record={record} field={f} />
			))}
		</Space>
	</LangWrapper>
)

export default DealContent
