import { Form, Col, Select, Switch } from 'antd'

import ListCard from '../../wrappers/listCard'

import InvestmentFilter from '../investmentFilter'
import CampaignFilter from '../campaignFilter'
import InvestorFilter from '../investorFilter'
import TrialFilter from '../trialFilter'
import StateFilter from '../stateFilter'
import DealFilter from '../dealFilter'
import ChatFilter from '../chatFilter'

const filterTypes = [
	{ value: 'state', label: 'State', renderer: StateFilter },
	{ value: 'investor', label: 'Field', renderer: InvestorFilter },
	{ value: 'quiz', label: 'Campaign', renderer: CampaignFilter },
	{ value: 'deal_investment', label: 'Deal Investment', renderer: DealFilter },
	{ value: 'chat', label: 'Chat', renderer: ChatFilter },
	{ value: 'investment', label: 'Investment', notExcludable: true, onlyOne: true, renderer: InvestmentFilter },
	{ value: 'trial', label: 'Trial', notExcludable: true, onlyOne: true, renderer: TrialFilter }
]

const FilterCardHeaderCols = ({ excludable, name, itemProps }) => {
	const form = Form.useFormInstance()
	const currentFilters = Form.useWatch(['filters'], form)
	const currentFiltersTypes = currentFilters?.map(filter => filter?.filter_type).filter(Boolean)

	const availableFilterTypes = filterTypes.map(filter =>
		filter.onlyOne ? { ...filter, disabled: currentFiltersTypes?.includes(filter.value) } : filter
	)

	return (
		<>
			{excludable && (
				<Col>
					<Form.Item {...itemProps} name={[name, 'exclude']} valuePropName='checked'>
						<Switch checkedChildren='Exclude' unCheckedChildren='Include' style={{ backgroundColor: '#d9d9d9', width: '100%' }} />
					</Form.Item>
				</Col>
			)}
			<Col span={8}>
				<Form.Item name={[name, 'filter_type']}>
					<Select options={availableFilterTypes} style={{ width: '100%' }} />
				</Form.Item>
			</Col>
		</>
	)
}

const FilterCard = ({ name, itemProps, remove }) => {
	const form = Form.useFormInstance()
	const currentFilterType = Form.useWatch(['filters', name, 'filter_type'], form)

	const currentFilterData = filterTypes.find(filter => filter.value === currentFilterType)

	const FilterRenderer = currentFilterData?.renderer

	return (
		<ListCard HeaderCols={FilterCardHeaderCols} name={name} remove={remove} excludable={!currentFilterData?.notExcludable} itemProps={itemProps}>
			{FilterRenderer && <FilterRenderer pathName={[name]} itemProps={itemProps} />}
		</ListCard>
	)
}

export default FilterCard
