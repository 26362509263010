import { CopyOutlined, ReloadOutlined } from '@ant-design/icons'
import { SchematicDataTableWithCreateModal } from '../../components/schematicComponents'

import { schema_list } from './schema'
import { Tooltip, notification } from 'antd'
import { useState } from 'react'
import { useApiUrl, useCustom } from '@refinedev/core'
import './styles.css'

const ProjectReportsList = () => {
	const apiUrl = useApiUrl()
	const [selectedId, setSelectedId] = useState(undefined)
	const [api, contextHolder] = notification.useNotification()

	const copy = text => {
		if (!text) return

		navigator.clipboard
			.writeText(text)
			.then(() => {
				api.success({
					description: 'Public link to the report copied!'
				})
			})
			.catch(err => {
				console.log(err.message)
			})
	}

	const { refetch: refresh } = useCustom({
		url: `${apiUrl}/project_reports/${selectedId}/refresh_public_id`,
		method: 'put',
		queryOptions: {
			enabled: false
		}
	})

	const getLink = async (id, _, refetch) => {
		await setSelectedId(id)
		await refresh()
		await refetch()
	}

	const copyLink = (_, { public_id }) => {
		if (!public_id) {
			return
		}
		const newLink = `${process.env.REACT_APP_API_URL}/form?public_id=${public_id}`
		copy(newLink)
	}

	return (
		<>
			{contextHolder}
			<SchematicDataTableWithCreateModal
				editable
				schema={schema_list}
				actions={{ del: true }}
				title='Project reports'
				resource='project_reports'
				className='projectReportsTable'
				dataTableProps={{
					search: true,
					rowClassName: ({ published, published_last }) => {
						let rowClass = ''
						if (!published) {
							rowClass += 'not_published'
						}
						if (published_last === false) {
							rowClass += ' not_last'
						}
						return rowClass
					}
				}}
				customActions={[
					{
						title: (
							<Tooltip title='Copy report link'>
								<CopyOutlined style={{ transform: 'scale(1.143)' }} />
							</Tooltip>
						),
						onClick: copyLink,
						isDisabled: ({ public_id }) => !public_id,
						style: {
							padding: '0px 5px'
						}
					},
					{
						title: (
							<Tooltip title='Refresh report link'>
								<ReloadOutlined style={{ transform: 'scale(1.143)' }} />
							</Tooltip>
						),
						onClick: getLink,
						style: {
							padding: '0px 5px'
						}
					}
				]}
			/>
		</>
	)
}

export default ProjectReportsList
