import { Col, Form, Input, InputNumber, Row } from 'antd'

import { DateInput } from '../inputs'
import { validateMessages } from '../../utils/constants'
import { InvestorSelector } from '../selectors/entitySelectors'

export const AgreementForm = props => {
	return (
		<Form {...props} labelCol={{ span: 6 }} validateMessages={validateMessages}>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<Form.Item label='Investor' name='investor_id' rules={[{ required: true }]}>
						<InvestorSelector />
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item label='Ag.number' name='number' rules={[{ required: true }]}>
						<Input />
					</Form.Item>
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<DateInput label='Date' name='date' rules={[{ required: true }]} />
				</Col>

				<Col span={12}>
					<DateInput label='Until' name='period' />
				</Col>
			</Row>
			<Row gutter={[16, 16]}>
				<Col span={12}>
					<Form.Item label='Amount' name='amount' rules={[{ required: true }]}>
						<InputNumber addonBefore='$' min={50} style={{ width: '100%' }} />
					</Form.Item>
				</Col>
			</Row>
		</Form>
	)
}
