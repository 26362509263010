import { useEffect } from 'react'
import { Button } from 'antd'
import { useApiUrl, useCustomMutation, useOne, useResource } from '@refinedev/core'
import { PoweroffOutlined } from '@ant-design/icons'

const ActiveButton = ({ campaignId, campaignName, campaignActive }) => {
	const { isSuccess, mutate } = useCustomMutation()
	const apiUrl = useApiUrl()

	const { resource, id } = useResource()

	const { refetch } = useOne({
		resource: resource?.name,
		id: id
	})

	useEffect(() => {
		if (isSuccess) refetch()
	}, [isSuccess, refetch])

	const setActiveCampaignHandler = () =>
		mutate({
			url: `${apiUrl}/campaigns/${campaignId}/set_active/${!campaignActive}`,
			method: 'post',
			successNotification: {
				message: `Campaign ${campaignName} ${!!campaignActive ? 'deactivated' : 'activated'}`,
				type: 'success'
			}
		})

	return (
		<Button type={campaignActive ? 'default' : 'primary'} icon={<PoweroffOutlined />} onClick={setActiveCampaignHandler}>
			{campaignActive ? 'Deactivate' : 'Activate'}
		</Button>
	)
}

export default ActiveButton
